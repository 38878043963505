<div class="flex items-stretch md:items-center justify-center min-h-full text-center md:px-2 lg:px-4">
  <div class="flex text-base text-left w-full max-w-full md:max-w-2xl md:px-4 md:my-8 lg:max-w-4xl">
    <nz-card>
      <div nz-card-grid [ngStyle]="{width: '100%'}">
        <div
          class="w-full relative flex items-center bg-white px-4 pt-14 pb-8 overflow-hidden sm:px-6 sm:pt-8 md:p-6 lg:p-8">
          <div class="w-full items-start">
            <div *ngIf="!hiddenCoverPhoto && house?.thumb && house?.thumb?.length"
              class="rounded-lg bg-gray-100 overflow-hidden h-60 mb-4">
              <img [src]="house?.thumb" [alt]="house?.name" class="object-center object-cover h-60 w-full bg-cover">
            </div>
            <div>
              <div class="w-full flex justify-between">
                <h2 class="text-xl underline font-bold text-gray-900 sm:pr-12">{{ house?.name }}</h2>
                <button class="text-sm italic text-slate-600" (click)="hiddenCover()"
                  *ngIf="house?.thumb && house?.thumb?.length" i18n>
                  {{ hiddenCoverPhoto ? translation['SHOW_COVER'] : translation['HIDE_COVER'] }}
                </button>
              </div>
              <section aria-labelledby="information-heading" class="mt-2">
                <span class="py-2 text-sm text-slate-500"
                  *ngIf="house?.description && house?.description?.length">{{house?.description}}</span>
                <div class="flex flex-col items-start pt-2">
                  <p class="text-md text-gray-900 pb-2"><nz-tag>{{translation['WEEKLY']}}: {{house?.prices?.weekly}}
                      €</nz-tag></p>
                  <div class="flex w-full flex-wrap">
                    <div *ngFor="let item of dailyPrices" class="sm:pr-2 text-xs sm:text-sm pb-1 sm:pb-0">
                      <nz-tag [nzColor]="item['day'] == 'Sat' || item['day'] == 'Sun' ? 'red' : 'default'">
                        {{ translation[item['day'].toUpperCase()] }} : {{ item['price'] }} €
                      </nz-tag>
                    </div>
                  </div>
                </div>
              </section>
              <section aria-labelledby="options-heading">
                <form>
                  <div class="mt-10" *ngIf="freeRooms.length">
                    <div class="flex items-center justify-between">
                      <h4 class="text-sm text-gray-900 font-medium">{{translation['FREE_ROOMS']}}</h4>
                    </div>
                    <fieldset class="mt-4">
                      <div class="grid grid-cols-4 gap-4">
                        <button *ngFor="let room of freeRooms" nz-button nzType="dashed" nzSize="large"
                          class="relative">
                          {{ room.roomNumber }}
                        </button>
                      </div>
                    </fieldset>
                  </div>
                  <div class="mt-10" *ngIf="bookedRooms.length">
                    <div class="flex items-center justify-between">
                      <h4 class="text-sm text-gray-900 font-medium">{{translation['BOOKED']}}</h4>
                    </div>
                    <fieldset class="mt-4">
                      <div class="grid grid-cols-4 gap-4">
                        <button *ngFor="let room of bookedRooms" nz-button nzType="default" nzSize="large"
                          class="relative">
                          {{ room.roomNumber }}
                        </button>
                      </div>
                    </fieldset>
                  </div>
                </form>
              </section>
            </div>
          </div>
        </div>
      </div>
    </nz-card>
  </div>
</div>