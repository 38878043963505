import { Component, OnDestroy, OnInit } from '@angular/core';
import { CashoutDto } from 'src/app/core/dto/cashout.dto';
import { ITranslation } from 'src/app/core/interfaces/translation.interface';
import { BookingService } from 'src/app/core/services/booking/booking.service';
import { CoreService } from 'src/app/core/services/core/core.service';
import { StoreService } from 'src/app/core/services/store/store.service';
import { TranslationService } from 'src/app/core/services/translation/translation.service';

@Component({
  selector: 'app-cash',
  templateUrl: './cash.component.html',
  styleUrls: ['./cash.component.css'],
})
export class CashComponent implements OnInit, OnDestroy {
  cashouts: CashoutDto[] = [];
  selectedCashout: CashoutDto | undefined;
  pageSize: number = 10;
  translation: ITranslation = {};
  subscriptions: any[] = [];

  constructor(
    private bookingService: BookingService,
    private translationService: TranslationService,
    private storeService: StoreService
  ) {
    this.subscriptions.push(
      this.translationService.translations.subscribe((translation) => {
        this.translation = translation;
      })
    );
    this.subscriptions.push(
      this.storeService.tablePageSize.subscribe((pageSize) => {
        this.pageSize = pageSize;
      })
    );
  }

  ngOnDestroy(): void {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.getCashout();
  }

  getCashout() {
    this.bookingService.getCashout().subscribe({
      next: (data: CashoutDto[]) => {
        data.sort((a, b) => {
          if (a.updatedAt! > b.updatedAt!) return 1;
          if (b.updatedAt! < a.updatedAt!) return -1;
          return 0;
        });
        this.cashouts = data;
      },
    });
  }

  onItemClick(cashout: CashoutDto): void {
    this.selectedCashout = cashout;
  }

  onCloseModal(): void {
    this.selectedCashout = undefined;
  }

  title(cash: CashoutDto): string {
    if (cash.user) {
      const firstName = cash.user.prename ? cash.user.prename : '';
      const lastName = cash.user.lastname ? cash.user.lastname : '';
      return `${firstName} ${lastName}`;
    } else {
      return cash.title ? cash.title : 'N/A';
    }
  }

  numberOfTransactionIds(cash: CashoutDto): number {
    if (cash.transactionIds) {
      return cash.transactionIds.length;
    }
    return 0;
  }

  totalAmount(cash: CashoutDto): string {
    if (cash.transactions && cash.transactions.length > 0) {
      let totalAmount = 0;
      cash.transactions.forEach((transaction) => {
        totalAmount = totalAmount + transaction.amount!;
      });
      return totalAmount.toFixed(2);
    }
    return '0';
  }

  getDateString(period: Date | string | undefined): string {
    return CoreService.getDateString(period, this.translationService);
  }

  getTableFields() {
    return this.allColumns;
  }
  allColumns: string[] = ['USER', 'NUMBER_OF_TRANSACTIONS', 'SUM_OF_TRANSACTIONS', 'DATE'];
  selectedColumns: string[] = [...this.allColumns];
  selectColumns(event: string[]) {
    this.selectedColumns = event;
  }

  showColumn(column: string) {
    return this.selectedColumns.includes(column);
  }
}
