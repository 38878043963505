import { ITranslation } from '../../interfaces/translation.interface';
import { TranslationDto } from './translation.dto';

export class TranslationEN implements TranslationDto {
  countries: ITranslation = {
    GERMANY: 'Germany',
  };
  translation: ITranslation = {
    HOUSES: 'Houses',
    OVERVIEW: 'Overview',
    CASH: 'Cash',
    WEEKLY_BOOKINGS: 'Weekly Bookings',
    CONTACTS: 'Contacts',
    SETTINGS: 'Settings',
    PROFILE: 'Profile',
    SEARCH_CLIENT: 'Search client...',
    SEARCH: 'Search',
    ADD_CLIENT: 'Add new client',
    DELETE: 'Delete',
    CLIENT_NO: 'Client No.',
    PHOTO: 'Photo',
    NAME: 'Name',
    DISPLAY_NAME: 'Display name',
    CATEGORY: 'Catgory',
    RATING: 'Rating',
    STATUS: 'Status',
    EMAIL: 'Email',
    CALL: 'Call',
    CREATED_AT: 'Created at',
    FIRST_NAME: 'First name',
    LAST_NAME: 'Last name',
    ALIAS: 'Alias',
    ADDRESS: 'Adress',
    DESCRIPTION: 'Description',
    SUBMIT: 'Submit',
    DESCRIPTION_PLACEHOLDER: 'Enter a description',
    INVALID_SOON: 'Invalid soon',
    INVALID: 'Invalid',
    MISSING_INFORMATION: 'Missing information',
    OK: 'OK',
    UPLOAD_AVATAR: 'Click or drag file to this area to upload',
    ALL: 'All',
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    WEB: 'Web',
    PHONE: 'Phone',
    NOTES: 'Notes',
    MOBILE: 'Mobile',
    PERSONAL_DOCUMENTS: 'Documents',
    EDIT: 'Edit',
    CREATE_BOOKING: 'Create a booking',
    BOOKING_PERIOD: 'Booking period',
    SELECT_HOUSE: 'Choose an house',
    DAILY: 'daily',
    WEEKLY: 'weekly',
    WEEKLY_PRICE: 'Weekly price',
    DAILY_PRICE: 'Daily price',
    HOUSE_INFO: 'House info',
    HOUSE: 'Hous',
    SELECT_ROOM: 'Choose a room',
    ROOM_INFO: 'Room info',
    TOTAL_PRICE: 'Total price',
    IS_ROOM_FIXED: 'Is room fixed?',
    ALLOW_DOUBLE_BOOKING: 'Allow double bookings?',
    MON: 'Mon',
    TUE: 'Tue',
    WED: 'Wed',
    THU: 'Thu',
    FRI: 'Fri',
    SAT: 'Sat',
    SUN: 'Sun',
    CONFIRM_EDIT_DOCUMEMT: 'Are you sure edit this document?',
    LANGUAGE: 'Language',
    DATE: 'Date',
    ACTIONS: 'Actions',
    IS_SIGNED: 'Is sigmed',
    TITLE: 'Title',
    TYPE: 'Type',
    TYPES: 'Types',
    TYPES_SELECT: 'Choose atype',
    CLEAR: 'Clear',
    DOWNLOAD_PDF: 'Download PDF',
    DATA: 'Data',
    CONTRACTS: 'Contracts',
    HISTORY: 'History',
    INVOICES: 'Invoices',
    TRANSACTIONS: 'Transactions',
    ADD_CONTRACT: 'Add new contract',
    GERMAN: 'German',
    ENGLISH: 'English',
    ROOM_NO: 'Room No.',
    INVOICE_NO: 'Invoice No.',
    TAX_RATE: 'Tax rate',
    VAT: 'Vat',
    NET: 'Net',
    TOTAL: 'Total',
    GROSS: 'Gross',
    PAID: 'Paid',
    STORNO: 'Storno',
    OPEN_AMOUNT: 'Open amount',
    CONFIRMED: 'Confirmed',
    BOOKED_AT: 'Booked at',
    BOOKED: 'Booked',
    CANCELED: 'Canceled',
    DELETED: 'Deleted',
    UNKOWN: 'Unkown',
    RECEIVED_AT: 'Received at',
    INVOICE_DATE: 'Invoice Date',
    CONTRACT_TEMPLATES: 'Contract Templates',
    MESSAGE_TEMPLATES: 'Message Templates',
    USERS: 'Users',
    EVENT: 'Event',
    CONTENT: 'Content',
    ENTER_TEXT: 'Enter text here...',
    CITY: 'City',
    TERMS: 'Terms',
    PRIVACY: 'Privacy',
    LOAN: 'Loan',
    RENT: 'Rent',
    HINTS: 'Hints',
    ADS: 'Ads',
    ADD_CONTRACT_TEMPLATE: 'Add new contract template',
    ADD_NEW_USER: 'Add new User',
    ADD_MESSAGE_TEMPLATE: 'Add new message template',
    USERNAME: 'Username',
    AVATAR: 'Avatar',
    ROLES: 'Roles',
    PASSWORD: 'Password',
    PLEASE_SELECT: 'Please select',
    READ_ONLY: 'Read only?',
    NUMBER_OF_TRANSACTIONS: 'Transaction count',
    SUM_OF_TRANSACTIONS: 'Sum of transactions',
    USER: 'User',
    OR: 'or',
    AND: 'and',
    PASTE_BOOKING: 'Paste booking',
    CUT_BOOKING: 'Cut this booking',
    CREATE_PAYMENT: 'Create a payment',
    EDIT_BOOKING: 'Edit booking',
    VIEW_CLIENT_DETAILS: 'View client details',
    CONFIRM_BOOKING: 'Confirm booking',
    CANCEL_BOOKING_BY: 'Cancel booking by',
    DELETE_BOOKING_BY_US: 'Delete booking by us',
    ROOM_NAME: 'Room name',
    BOOKING: 'Booking',
    LOGOUT: 'Logout',
    AMOUNT: 'Amount',
    PAYMENT_DATE: 'Payment date',
    ADD_NOTES: 'Add a note?',
    PAYMENT: 'Payment',
    PRICE: 'Pride',
    FIXED_ROOM: 'Fixed Room?',
    AUTO_CREATE_NEXT_BOOKING: 'Create next booking automatically?',
    CONFIRM_CREATION_OF_BOOKING: 'Are you sure creating new booking on this house/room, period?',
    SELECT_CLIENT: 'Select a client',
    CLIENT: 'Client',
    PERIOD_END_DATE: 'End date',
    NEW_BOOKING: 'New Booking',
    NEW_BOOKING_SUCCESS: 'New booking is created successfully.',
    ERROR_MSG: 'There was an error!',
    CONFIRM_DELETE_CLIENT: 'Are you sure delete this client(s)?',
    FRONT_SIDE: 'Front Side',
    BACK_SIDE: 'Back Side',
    UPDATE_DOCUMENT: 'Update document',
    REPLACE_DOCUMENT_TITLE: 'Replace document',
    REPLACE_DOCUMENT: 'You want to replace this doucment with new one?',
    PRENAME: 'Prename',
    SURNAME: 'Lastname',
    DATEOFBIRTH: 'Date of birth',
    DATEOFISSUE: 'Date of issue',
    DATEOFEXPIRY: 'Valid until',
    IDNUMBER: 'ID Number',
    ISSUEDBY: 'Issued by',
    PLACEOFBIRTH: 'Place of birth',
    NATIONALITY: 'Nationality',
    ISSUINGCOUNTRY: 'Issuing Country',
    ISSUINGCITY: 'Issuing City',
    STREET: 'Street',
    POSTCODE: 'Postcode',
    COUNTRY: 'Country',
    ISSUINGNUMBER: 'Issuing number',
    VALIDFOR: 'Valid for',
    BOOKING_CANCELED: 'Die Buchung wurde storniert.',
    CONFIRM_DELETE_BOOKING: 'Do you want to delete this booking?',
    COPY_BOOKING_MSG: 'The booking is copied',
    BOOKING_DELETED_MSG: 'The booking is deleted',
    BOOKING_UPDATED_MSG: 'The booking is updated successfully.',
    WEEK: 'Week',
    CONFIRM_BOOKING_SUCCESS: 'The booking is confirmed successfully.',
    NEW_PAYMENT: 'New Payment',
    PAYMENT_CREATION_SUCCESS: 'The payment is created successfully.',
    DAY: 'Day',
    START: 'Start',
    END: 'End',
    WEEKLY_PDF_WITH_ALL_ROOMS: 'PDF with all rooms',
    WEEKLY_PDF_WITHOUT_FREE_ROOMS: 'PDF without free rooms',
    WEEKLY_PDF_DOWNLOAD_CONFIRM: 'Do you want to download pdf?',
    FREE: 'Free',
    NOT_PAID: 'Not paid',
    TOTAL_ROOMS: 'Total Rooms',
    FREE_ROOMS: 'Free Rooms',
    BOOKED_ROOMS: 'Booked Rooms',
    UPDATE_BOOKING_SUCCESS: 'The booking was updated successfully.',
    NOT_ASSIGNED_CLIENTS: 'Not assigned clients',
    SHOW_COVER: 'Show cover',
    HIDE_COVER: 'Hide cover',
    REMOVE_ROOM_FROM_BOOKING: 'Remove room from booking',
    NEW_CONTRACT_TEMPLATE: 'New contract template',
    CONTRACT_TEMPLATE: 'Contract template',
    NEW_CONTRACT_TEMPLATE_SUCCESS: 'New contract template is created.',
    UPDATE_CONTRACT_TEMPLATE_SUCCESS: 'Contract template was updated.',
    DELETE_CONTRACT_TEMPLATE_SUCCESS: 'Contract template was deleted.',
    CONFIRM_DELETE_CONTRACT_TEMPLATE: 'Are you sure to delete this contract template?',
    NEW_MESSAGE_TEMPLATE: 'New message template',
    MESSAGE_TEMPLATE: 'Message template',
    NEW_MESSAGE_TEMPLATE_SUCCESS: 'New message template is created.',
    UPDATE_MESSAGE_TEMPLATE_SUCCESS: 'Message template was updated.',
    DELETE_MESSAGE_TEMPLATE_SUCCESS: 'Message template was deleted.',
    CONFIRM_DELETE_MESSAGE_TEMPLATE: 'Are you sure to delete this message template?',
    NEW_USER_SUCCESS: 'New User is created.',
    UPDATE_USER_SUCCESS: 'User was updated.',
    DELETE_USER_SUCCESS: 'User was deleted.',
    CONFIRM_DELETE_USER: 'Are you sure to delete this user?',
    CREATE_NEW_CLIENT: 'Create a new client',
    DELETE_CLIENT_SUCCESS: 'Client(s) were deleted.',
    DELETE_CLIENTS: 'Delete Clients',
    OTHER: 'Other',
    NEW_HOUSE: 'Create a new house',

    MONDAY: 'Monday',
    TUESDAY: 'Tuesday',
    WEDNESDAY: 'Wednesday',
    THURSDAY: 'Thursday',
    FRIDAY: 'Friday',
    SATURDAY: 'Saturday',
    SUNDAY: 'Sunday',
    PRICES: 'Prices',
    HOUSE_NAME: 'House name',
    ROOMS: 'Rooms',
    INFO: 'Info',
    UPLOAD_IMAGE: 'Click or drag image to this area to upload',
    SQUARE_METERS: 'Square meters',
    FLOOR: 'Floor',
    BOOKING_RATIO: 'Booking ratio',
    EDIT_HOUSE: 'Edit house',
    NEW_ROOM: 'New room',
    ADD_NEW_ROOM: 'Create a new room',
    WARNING: 'Warning',
    EDIT_ROOM: 'Edit room',
    ROOM_COLOR: 'Room color',
    INVOICE: 'Invoice',
    START_DATE: 'Start Date',
    END_DATE: 'End Date',
    OPEN: 'Open',
    ANALYSIS: 'Analysis',
    OVERVIEW_PERIOD: 'Overview by Period',
    CLIENTS: 'Clients',
    BACK: 'Back',
    ADD_MORE: 'Add more',
    TURNOVER: 'Turnover',
    BOOKINGS: 'Bookings',
    PAST_TURNOVER: 'Bisheriger Umsatz',
    PAST_BOOKINGS: 'Bisherige Buchungen',
    PAST_OPEN: 'Bisher Offen',
    FUTURE_TURNOVER: 'Zukünftiger Umsatz',
    FUTURE_BOOKINGS: 'Zukünftige Buchungen',
    FUTURE_OPEN: 'Zukünftig Offen',
    NEW_CONTRACT: 'New Contract',
    CONTRACT: 'Contract',
    SAVE: 'Save',
    GO_TO: 'Go back to',
    GPASS: 'GPass',
    IDCARD: 'Id Card',
    HPASS: 'HPass',
    NATIONWIDE: 'Nationwide',
    THURINGA: 'Thuringia',
    SCHLESWIG_HOLSTEIN: 'Schleswig Holstein',
    SAXONY_ANHALT: 'Saxony-Anhalt',
    SAXONY: 'Saxony',
    SAARLAND: 'Saarland',
    RHINELAND_PALATINATE: 'Rhineland-Palatinate',
    NORTH_RHINE_WESTPHALIA: 'North Rhine-Westphalia',
    LOWER_SAXONY: 'Lower Saxony',
    MECKLENBURG_WESTERN_POMERANIA: 'Mecklenburg-Western Pomerania',
    HESSE: 'Hesse',
    HAMBURG: 'Hamburg',
    BREMEN: 'Bremen',
    BRANDENBURG: 'Brandenburg',
    BERLIN: 'Berlin',
    BAVARIA: 'Bavaria',
    BADEN_WUERTTEMBERG: 'Baden Wuerttemberg',
    UNRELIABLE: 'Unreliable',
    RELIABLE: 'Reliable',
    ALIAS_ORG_ID: 'Verwaltungsnummer', //issuingNumber
    ALIAS_ID: 'HPass Nummer', // idNumber
    NEW_DOCUMENT: 'New document',
    NEW: 'New',
    NEW_DOCUMENT_CREATED_SUCCESSFULLY: 'document was created successfully.',
    CUT_ALL_BOOKINGS: 'Cut all bookings',
    PASTE_ALL_BOOKINGS: 'Paste all bookings',
    AT: 'at',
    CLIENT_TYPE: 'Client type',
    DOMINA: 'Domina',
    TS: 'TS',
    WOMAN: 'Woman',
    EMPTY_STRING: '',
    DELETE_IMAGE: 'Delete image',
    DOCUMENT_IMAGE_COULD_NOT_BE_DELETED: 'Image could not be deleted for document',
    UNCONFIRM_BOOKING: 'Unconfirm booking',
    UNCONFIRM_BOOKING_SUCCESS: 'Unconfirmation successfully executed.',
    SINCE: 'Since',
    HOUSENO: 'House No.',
    ARRIVED_IN_HOUSE: 'Arrived in house',
    CLIENT_ARRIVED_BOOKING_SUCCESS: 'Client state was changed to arrived.',
    SIGN_IN: 'Sign In',
    PASSWORD_FORGOTTEN: 'Forgot your password?',
    REMEMBER_ME: 'Remember me',
    SIGN_IN_ACCOUNT: 'Sign in to your account',
    EMAIL_OR_USER: 'Email or username',
    PAST_DAYS: 'Past days',
    FUTURE_DAYS: 'Future days',
    DAYS: 'Days',
    CREATE_OTHER_PAYMENT: 'Other Payments',
    PAYMENT_PURPOSE: 'Purpose of payment',
    PAYMENT_CATEGORY: 'Payment category',
    FEE: 'Fee',
    DEPOSIT: 'Deposit',
    PAYMENT_CREATION_FAILED: 'The payment could not be processed',
    PAYMNET_DATE_ONLY_WHEN_RECEIVED: 'Please only select a date, when the payment was received.',
    UNDELETE: 'Reactivate',
    CONFIRM_UNDELETE_CLIENT: 'Are you sure to reactive this client?',
    CREATED: 'Created',
    REFUND: 'Refund',
    OPEN_AMOUNT_OTHER: 'Other open amounts',
    PROVE: 'Proves',
    ADD_PROVE: 'Add prove',
    PROVE_EDIT: 'Edit proves',
    DOCUMENT: 'Document',
    PROVE_IMAGE_COULD_NOT_BE_DELETED: 'The prove could not be deleted.',
    ROTATE_IMAGE: 'Rotate image',
    PROVE_IMAGE_COULD_NOT_BE_ROTATED: 'The prove could not be rotated!',
    MESSAGES: 'Messages',
    CANCELED_DAYS: 'Canceled days',
    CANCELED_BOOKINGS: 'Canceled bookings',
    CHECKED: 'Checked',
    ROOM: 'Room',
    UPDATE_PAYMENT: 'Edit Payment',
    CREATE_BOOKING_FOR_EACH_DAY: 'Create booking for each day',
    IS_NEW_PAYMENT: 'Is a new payment?',
    FEES: 'Fees',
    HOUSES_BOOKINGS: 'House Bookings',
    ROOMS_BOOKINGS: 'Room Bookings',
    HOUSES_TURNOVER: 'Hosue Payments',
    ROOMS_TURNOVER: 'Room Payments',
    UNPAID: 'Unpaid',
    PAID_FEE: 'Paid fee',
    OPEN_AMOUNT_FEE: 'Unpiad fee',
    CLIENT_NAME: 'Client',
    PAID_DEPOSIT: 'Paid Deposit',
    OPEN_AMOUNT_DEPOSIT: 'Unpaid Deposit',
    COMMENT: 'Comment',
    REFUND_DEPOSIT: 'Refund deposit',
    TEMPLATES: 'Templates',
    FINANCIAL: 'Financial',
    REPORTS: 'Reports',
    ROOM_PLAN: 'Room Plan',
    NO_CONTRACT: 'No contract',
    CONTRACT_SIGNED: 'Contract signed',
    CONTRACT_NOT_SIGNED: 'Contract not signed',
    YEAR: 'Year',
    MONTH: 'Month',
    CANCELED_TURNOVER: 'Canceled turnover',
    CANCELED_PAID_TURNOVER: 'Canceled paid turnover',
    UPDATED_AT: 'Updated at',
    YEARLY: 'Yearly',
    MONTHLY: 'Monthly',
    LOG: 'Log',
    SHOW_ALL_ROOMS: 'Show all rooms',
    SHOW_NO_ROOMS: 'Show no rooms',
    PAST_PAID: 'Paid until now',
    FUTURE_PAID: 'Paid for future',
    PAST_FEES: 'Bisherige Gebühren',
    FUTURE_FEES: 'Zukünftige Gebühren',
    TOTAL_FEES: 'Gebühren Gesamt',
    OPEN_FEES: 'Offene Gebühren',
    PAST_PAID_FEES: 'Bisher bezahlte Gebühren',
    FUTURE_PAID_FEES: 'Im Voraus bezahlte Gebühren',
    TOTAL_PAID_FEES: 'Gesamt bezahlte Gebühren',
  };
}
