import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ITranslation } from 'src/app/core/interfaces/translation.interface';
import { TranslationService } from 'src/app/core/services/translation/translation.service';

@Component({
  selector: 'table-column-filter',
  templateUrl: './table-column-filter.component.html',
  styleUrls: ['./table-column-filter.component.scss'],
})
export class TableColumnFilterComponent implements OnInit, OnDestroy {
  _fields: string[] = [];
  @Input() set fields(value: string[]) {
    this._fields = value;
    if (this.selectedFields.length === 0) {
      this.selectedFields = value;
    }
  }

  get fields(): string[] {
    return this._fields;
  }
  @Output() update = new EventEmitter();

  selectedFields: string[] = [];
  public translation: ITranslation = {};
  private subscriptions: any[] = [];
  constructor(private translationService: TranslationService) {
    this.subscriptions.push(
      this.translationService.translations.subscribe((translation) => {
        this.translation = translation;
      })
    );
  }
  ngOnInit(): void {}
  ngOnDestroy(): void {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  isNotSelected(value: string): boolean {
    return this.selectedFields.indexOf(value) === -1;
  }

  updateFields() {
    this.update.emit(this.selectedFields);
  }
}
