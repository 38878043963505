import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ITranslation } from 'src/app/core/interfaces/translation.interface';
import { TokenStorageService } from 'src/app/core/services/token/token-storage.service';
import { TranslationService } from 'src/app/core/services/translation/translation.service';

@Component({
  selector: 'image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.css'],
})
export class ImageModalComponent implements OnInit, OnDestroy {
  @Input() imageUrl?: String;

  @Output() close = new EventEmitter<boolean>();

  @Output() rotate = new EventEmitter<number>();
  @Output() doDelete = new EventEmitter<boolean>();

  private subscriptions: any[] = [];
  rotation = 0;
  translation: ITranslation = {};

  zoom = 1;

  constructor(
    private translationService: TranslationService,
    public token: TokenStorageService,
    private modal: NzModalService
  ) {
    this.subscriptions.push(
      this.translationService.translations.subscribe((translation) => {
        this.translation = translation;
      })
    );
  }

  ngOnDestroy(): void {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  ngOnInit(): void {}

  closeModal(): void {
    this.close.emit(true);
  }

  rotateLeft() {
    this.rotation -= 90;
  }

  rotateRight() {
    this.rotation += 90;
  }

  rotateStr(): string {
    let transform = '';
    if (this.rotation % 360 !== 0) {
      transform = `rotate(${this.rotation}deg)`;
    }
    if (this.zoom !== 1) {
      transform += ` scale(${this.zoom})`;
    }
    return transform;
  }

  zoomIn() {
    this.zoom += 0.25;
  }

  zoomOut() {
    if (this.zoom > 0.25) {
      this.zoom -= 0.25;
    }
  }

  save() {
    this.rotate.emit(this.rotation);
    // this.closeModal();
  }

  deleteImage() {
    this.modal.confirm({
      nzTitle: this.translation['DELETE_DOCUMENT_IMAGE_CONFIRM'],
      nzContent: this.translation['DELETE_IMAGE_FROM_DOCUMENT'],
      nzCancelText: this.translation['CANCEL'],
      nzOkText: this.translation['DELETE'],
      nzMaskClosable: true,
      nzOnOk: () => {
        this.doDeleteImage();
      },
    });
  }

  doDeleteImage() {
    this.doDelete.emit(true);
  }
}
