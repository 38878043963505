import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { AppVersion } from 'src/app/app.version';
import { ClientDto } from 'src/app/core/dto/client.dto';
import { ValidDto } from 'src/app/core/dto/valid.dto';
import { ITranslation } from 'src/app/core/interfaces/translation.interface';
import { ContactService } from 'src/app/core/services/contact/contact.service';
import { CoreService } from 'src/app/core/services/core/core.service';
import { SearchService } from 'src/app/core/services/core/search.service';
import { SharedService } from 'src/app/core/services/shared/shared.service';
import { StoreService } from 'src/app/core/services/store/store.service';
import { TokenStorageService } from 'src/app/core/services/token/token-storage.service';
import { TranslationService } from 'src/app/core/services/translation/translation.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './app-sidebar.component.html',
  styleUrls: ['./app-sidebar.component.css'],
})
export class AppSidebarComponent implements OnInit, OnDestroy {
  // collapseShow = 'hidden';
  collapseShow = '';
  pageIndex = 0;
  currentRoute?: string;
  clients: ClientDto[] = [];
  filteredClients: ClientDto[] = [];
  isCollapsed = false;
  term: string = '';

  isLoading = false;
  translations: ITranslation = {};
  subscriptions: any[] = [];

  version = AppVersion.app_version;
  constructor(
    public router: Router,
    public tokenService: TokenStorageService,
    private contactService: ContactService,
    private sharedService: SharedService,
    public translationService: TranslationService,
    private storeService: StoreService
  ) {
    this.subscriptions.push(
      this.sharedService.getClientUpdateEvent().subscribe(() => {
        // this.getClients(false);
        this.contactService.getClients();
      })
    );
    this.subscriptions.push(
      this.translationService.translations.subscribe((translations) => {
        this.translations = translations;
      })
    );
    this.subscriptions.push(
      this.storeService.clients.subscribe((clients) => {
        this.clients = clients.filter((c) => {
          return c.active;
        });
        this.filteredClients = this.clients;
      })
    );
  }

  ngOnDestroy(): void {
    for (let subscription of this.subscriptions) {
      try {
        subscription.unsubscribe();
      } catch (error) {
        console.log('unsubscribe did not work');
      }
    }
  }

  ngOnInit() {
    this.changeStatus();
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        this.changeStatus();
      }
    });
    this.getClients(true);
  }

  isAdmin() {
    return this.tokenService.isAdmin();
  }
  toggleCollapseShow(classes: string) {
    if (this.collapseShow === classes) {
      this.collapseShow = '';
      this.storeService.sidebarCollapsed.next(false);
      this.isCollapsed = false;
    } else {
      this.collapseShow = classes;
      this.storeService.sidebarCollapsed.next(true);
      this.isCollapsed = true;
    }
  }

  collapseShowM = '';

  toggleSub(id: string) {
    const el = document.getElementById(id);
    if (el) {
      el.classList.toggle('sub-hidden');
    }
  }

  getCol2() {
    if (window.innerWidth < 768) {
      return this.collapseShowM;
    } else {
      return '';
    }
  }
  getCol() {
    if (window.innerWidth < 768) {
      return this.collapseShowM;
    } else {
      return this.collapseShow;
    }
    /*
    if (this.collapseShowM && this.collapseShowM != '') {
      return this.collapseShowM;
    } else {
      return this.collapseShow;
    }
    */
  }
  toggle(classes: string) {
    if (this.collapseShowM === classes) {
      this.collapseShowM = '';
    } else {
      this.collapseShowM = classes;
    }
  }

  changeStatus() {
    const urls = this.router.url.trim().split('/');
    let index = 2;
    if (['de', 'en', 'fr'].indexOf(urls[1]) >= 0 && urls.length <= 2) {
      index = 1;
    }
    if (urls[index] == this.translationService.getLanguage()) {
      this.pageIndex = 0;
    }
    if (urls[index] && urls[index] == 'houses') {
      this.pageIndex = 1;
    }
    if (urls[index] && urls[index] == 'cash') {
      this.pageIndex = 2;
    }
    if (urls[index] && urls[index] == 'calendar') {
      this.pageIndex = 3;
    }
    if (urls[index] && urls[index] == 'contacts') {
      this.pageIndex = 4;
    }
    if (urls[index] && urls[index] == 'settings') {
      this.pageIndex = 5;
    }
    if (urls[index] && urls[index] == 'profile') {
      this.pageIndex = 6;
    }
    if (urls[index] && urls[index] == 'deposit') {
      this.pageIndex = 7;
    }
    if (urls[index] && urls[index] == 'messages') {
      this.pageIndex = 8;
    }
    if (urls[index] && urls[index] == 'dashboard') {
      this.pageIndex = 9;
    }
  }

  logout() {
    this.tokenService.removeToken();
    this.storeService.history.next(['auth/login']);
    this.router.navigate([this.translationService.getLanguage(), 'auth', 'login']);
  }

  getClients(showLoading: boolean) {
    /*
    this.isLoading = showLoading && true;
    this.contactService.getClients().subscribe({
      next: (data) => {
        this.isLoading = showLoading && false;
        this.clients = data.sort((a, b) => {
          if (new Date(a.updatedAt!) < new Date(b.updatedAt!)) {
            return 1;
          }
          return -1;
        });
        
        this.filteredClients = this.clients;
      },
    });
    */
  }

  getShortListClients() {
    return this.filteredClients.slice(0, 50);
  }

  onClickContact(client: ClientDto) {
    console.log('click single contacts');
    this.pageIndex = 4;
    this.toggle('mobile-hidden');
    //this.storeService.history.value.push(`${this.translationService.getLanguage()}/contacts/${client._id}`);
    //this.storeService.history.next(this.storeService.history.value);
    this.router.navigate([this.translationService.getLanguage(), 'contacts', client._id]);
  }

  changeTerm(term: string) {
    if (term.length === 0) {
      this.filteredClients = this.clients;
    } else {
      this.filteredClients = this.clients.filter((c: ClientDto) => {
        return SearchService.searchContact(c, term, this.translations);
      });
    }
  }
  getUserNameLetters(client?: ClientDto): string {
    return CoreService.getUserNameLetters(client);
  }

  getClientTypeColor(clientType: string) {
    return CoreService.getClientTypeColor(clientType);
  }

  clickOverview(): void {
    this.pageIndex = 0;
    this.toggle('mobile-hidden');
    this.storeService.history.value.push(`${this.translationService.getLanguage()}`);
    this.storeService.history.next(this.storeService.history.value);
    this.router.navigate([this.translationService.getLanguage()]);
  }
  clickHouses(): void {
    this.pageIndex = 1;
    this.toggle('mobile-hidden');
    this.storeService.history.value.push(`${this.translationService.getLanguage()}/houses`);
    this.storeService.history.next(this.storeService.history.value);
    this.router.navigate([this.translationService.getLanguage(), 'houses']);
  }
  clickCash(): void {
    this.pageIndex = 2;
    this.toggle('mobile-hidden');
    this.storeService.history.value.push(`${this.translationService.getLanguage()}/cash`);
    this.storeService.history.next(this.storeService.history.value);
    this.router.navigate([this.translationService.getLanguage(), 'cash']);
  }
  clickBookings(): void {
    this.pageIndex = 3;
    this.toggle('mobile-hidden');
    this.storeService.history.value.push(`${this.translationService.getLanguage()}/calendar`);
    this.storeService.history.next(this.storeService.history.value);
    this.router.navigate([this.translationService.getLanguage(), 'calendar']);
  }
  clickContacts(): void {
    console.log('click contacts', `${this.translationService.getLanguage()}/contacts`);
    this.pageIndex = 4;
    this.toggle('mobile-hidden');
    this.storeService.history.value.push(`${this.translationService.getLanguage()}/contacts`);
    this.storeService.history.next(this.storeService.history.value);
    console.log('pre router');
    this.router.navigate([this.translationService.getLanguage(), 'contacts']);
    console.log('after router');
  }
  clickDeposit(): void {
    this.pageIndex = 7;
    this.toggle('mobile-hidden');
    this.storeService.history.value.push(`${this.translationService.getLanguage()}/deposit`);
    this.storeService.history.next(this.storeService.history.value);
    this.router.navigate([this.translationService.getLanguage(), 'deposit']);
  }
  clickMessages(): void {
    this.pageIndex = 8;
    this.toggle('mobile-hidden');
    this.storeService.history.value.push(`${this.translationService.getLanguage()}/messages`);
    this.storeService.history.next(this.storeService.history.value);
    this.router.navigate([this.translationService.getLanguage(), 'messages']);
  }
  clickSettings(): void {
    this.pageIndex = 5;
    this.toggle('mobile-hidden');
    this.storeService.history.value.push(`${this.translationService.getLanguage()}/settings`);
    this.storeService.history.next(this.storeService.history.value);
    this.router.navigate([this.translationService.getLanguage(), 'settings']);
  }
  clickProfile(): void {
    this.pageIndex = 6;
    this.toggle('mobile-hidden');
    this.storeService.history.value.push(`${this.translationService.getLanguage()}/profile`);
    this.storeService.history.next(this.storeService.history.value);
    this.router.navigate([this.translationService.getLanguage(), 'profile']);
  }
  clickAnalysis(): void {
    this.pageIndex = 9;
    this.toggle('mobile-hidden');
    this.storeService.history.value.push(`${this.translationService.getLanguage()}/dashboard`);
    this.storeService.history.next(this.storeService.history.value);
    this.router.navigate([this.translationService.getLanguage(), 'dashboard']);
  }

  getCategories(categories: ValidDto[]): string {
    let result = '';
    for (let category of categories) {
      if (category.checked) {
        result += category.name + ' ';
      }
    }
    return result;
  }
}
