import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HistoryDto } from 'src/app/core/dto/history.dto';
import { CoreService } from 'src/app/core/services/core/core.service';
import { TranslationService } from 'src/app/core/services/translation/translation.service';

@Component({
  selector: 'booking-detail-box',
  templateUrl: './booking-detail.component.html',
  styleUrls: ['./booking-detail.component.css'],
  animations: [
    trigger('backdropFade', [
      transition(':enter', [
        style({
          opacity: 0,
        }),
        animate('300ms ease-in', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({
          opacity: 1,
        }),
        animate('200ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
    trigger('fade', [
      transition(':enter', [
        style({
          transform: 'translateX(28rem)',
        }),
        animate('500ms ease-in', style({ transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        style({
          transform: 'translateX(0)',
        }),
        animate('500ms ease-out', style({ transform: 'translateX(28rem)' })),
      ]),
    ]),
  ],
})
export class BookingDetailComponent implements OnInit {
  @Input() open?: boolean;
  @Input() history?: HistoryDto | null;
  @Output() close = new EventEmitter();

  clientPlaceHolder =
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxwa1y6Q-tWrqCjBfDQQxWUeXxXROgDlLJ22wbJcVHCA-LX3dDKCxjfojzK-wQOpctwi0&usqp=CAU';

  constructor(private translationService: TranslationService) {}

  ngOnInit(): void {}

  clickClose(e: MouseEvent): void {
    e.stopPropagation();
    this.close.emit();
  }

  get priceLabel(): string {
    if (this.history?.room && this.history.room.type) {
      return this.history.room.type;
    } else if (this.history?.house && this.history.house.type) {
      return this.history.house.type;
    }
    return '';
  }

  get price(): number {
    if (this.history?.total && this.history.total > 0) {
      return this.history.total;
    } else {
      if (this.history?.invoice && this.history.invoice.total) {
        return this.history.invoice.total;
      } else {
        if (this.history?.room) {
          return this.history.room.type?.toLowerCase() == 'weekly'
            ? this.history.room.prices?.weekly!
            : this.totalPrice(true);
        } else {
          return this.history?.house?.type?.toLowerCase() == 'weekly'
            ? this.history.house.prices?.weekly!
            : this.totalPrice(false);
        }
      }
    }
    return 0;
  }

  totalPrice(isRoom: boolean): number {
    const today: Date = new Date();
    if (today.getDay() == 1) {
      return isRoom ? this.history?.room?.prices?.daily?.monday! : this.history?.house?.prices?.daily?.monday!;
    }
    if (today.getDay() == 2) {
      return isRoom ? this.history?.room?.prices?.daily?.tuesday! : this.history?.house?.prices?.daily?.tuesday!;
    }
    if (today.getDay() == 3) {
      return isRoom ? this.history?.room?.prices?.daily?.wednesday! : this.history?.house?.prices?.daily?.wednesday!;
    }
    if (today.getDay() == 4) {
      return isRoom ? this.history?.room?.prices?.daily?.thursday! : this.history?.house?.prices?.daily?.thursday!;
    }
    if (today.getDay() == 5) {
      return isRoom ? this.history?.room?.prices?.daily?.friday! : this.history?.house?.prices?.daily?.friday!;
    }
    if (today.getDay() == 6) {
      return isRoom ? this.history?.room?.prices?.daily?.saturday! : this.history?.house?.prices?.daily?.saturday!;
    }

    if (today.getDay() == 0) {
      return isRoom ? this.history?.room?.prices?.daily?.sunday! : this.history?.house?.prices?.daily?.sunday!;
    }
    return 0;
  }

  getColor(key: string): string {
    // @ts-ignore
    return CoreService.colors[key];
  }

  getColorString(key: string): string {
    return `color: ${this.getColor(key)}`;
  }

  getDateString(date: Date | string | undefined) {
    if (typeof date === 'string') {
      return CoreService.getDateString(new Date(date), this.translationService);
    } else if (date === undefined) {
      return '';
    } else {
      return CoreService.getDateString(date, this.translationService);
    }
  }
}
