import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ITranslation } from 'src/app/core/interfaces/translation.interface';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { TranslationService } from 'src/app/core/services/translation/translation.service';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css'],
})
export class PasswordComponent implements OnInit, OnDestroy {
  form = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required]),
  });

  isSubmitting: boolean = false;
  translation: ITranslation = {};
  subscriptions: any[] = [];

  constructor(
    private router: Router,
    private authService: AuthService,
    private notification: NzNotificationService,
    private message: NzMessageService,
    private translationService: TranslationService
  ) {
    this.subscriptions.push(
      this.translationService.translations.subscribe((translation) => {
        this.translation = translation;
      })
    );
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
  ngOnInit(): void {}

  submit() {
    this.isSubmitting = true;
    this.authService.passwordResetRequest(this.form.value.email).subscribe({
      next: (data) => {
        this.isSubmitting = false;
        if (data['status'] == 'done') {
          this.notification.blank(
            'Password reset request',
            'Reset code was sent to your email, Please check your email.',
            { nzDuration: 0 }
          );
          this.router.navigate([this.translationService.getLanguage(), 'auth', 'reset-password']);
        } else {
          this.message.warning('Something went wrong');
        }
      },
    });
  }
}
