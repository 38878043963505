import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { authInterceptorProviders } from './core/interceptors/auth.interceptor';
import { errorInterceptorPorviders } from './core/interceptors/error.interceptor';

import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { registerLocaleData } from '@angular/common';
import de from '@angular/common/locales/de';
import { FormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IconModule } from '@ant-design/icons-angular';
import { Loader } from '@googlemaps/js-api-loader';
import { NZ_I18N, de_DE } from 'ng-zorro-antd/i18n';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminGuard } from './core/guards/admin.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { LanguageGuard } from './core/guards/language.guard';
import { AuthModule } from './features/auth/auth.module';
import { HomeModule } from './features/home/home.module';

registerLocaleData(de);

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        AuthModule,
        HomeModule,
        FormsModule,
        IconModule,
        NzMessageModule,
        NzNotificationModule,
        NgxGpAutocompleteModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: true,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        })], providers: [
        AuthGuard,
        AdminGuard,
        LanguageGuard,
        authInterceptorProviders,
        errorInterceptorPorviders,
        { provide: NZ_I18N, useValue: de_DE },
        {
            provide: Loader,
            useValue: new Loader({
                apiKey: 'AIzaSyBo9fBeErV_d4x7UleLOSvupiQ0zWRkBNE',
                libraries: ['places'],
            }),
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
