import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { parseISO } from 'date-fns';
import { Observable, map } from 'rxjs';
import { FilterParams, FilterParams2 } from 'src/app/interfaces/calendar.filter';
import { environment } from 'src/environments/environment';
import { BookingDto } from '../../dto/booking.dto';
import { CashoutDto } from '../../dto/cashout.dto';
import { HouseDto } from '../../dto/house.dto';
import { InvoiceDto } from '../../dto/invoice.dto';
import { PasteBookingDto } from '../../dto/paste.dto';
import { IWeek, IYear } from '../../interfaces/weeks.interface';
import { SocketService } from '../socket/socket.service';
import { StoreService } from '../store/store.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  // private initalLoad = false;
  // private isVisible = false;
  private login = false;

  constructor(private http: HttpClient, private storeService: StoreService, private socketService: SocketService) {
    /*
    this.getAvailableYearList().subscribe((list) => {
      for (let year of list) {
        if (this.login) {
          this.getWeekList(year);
        }
      }
    });
    */
    this.storeService.login.subscribe((login) => {
      this.login = login;
    });
  }

  getBookings(): Observable<HouseDto[]> {
    return this.http.get(environment.apiUrl + 'bookings/overview', httpOptions).pipe(map((data: any) => data));
  }

  getCashout(): Observable<CashoutDto[]> {
    return this.http.get(environment.apiUrl + 'cashout/details', httpOptions).pipe(map((data: any) => data));
  }

  getHousesWithBookings(month: number, year: number): Observable<any> {
    return this.http
      .get(environment.apiUrl + `bookings/list?year=${year}&month=${month}`, httpOptions)
      .pipe(map((data: any) => data));
  }

  getBookingsbyYear(year: number): Observable<any> {
    return this.http.get(environment.apiUrl + `bookings/list?year=${year}`, httpOptions).pipe(map((data: any) => data));
  }

  getInvoices(houseId: string): Observable<InvoiceDto[]> {
    return this.http
      .get(environment.apiUrl + 'houses/' + houseId + '/invoices/', httpOptions)
      .pipe(map((data: any) => data));
  }

  cancelBooking(bookingId: string): Observable<any> {
    return this.http.delete(environment.apiUrl + 'bookings/' + bookingId, httpOptions).pipe(map((data: any) => data));
  }

  updateBooking(bookingId: string, roomId: string | null, houseId: string | null): Observable<any> {
    return this.http
      .put(environment.apiUrl + 'bookings/' + bookingId, { roomId: roomId, houseId: houseId }, httpOptions)
      .pipe(map((data: any) => data));
  }

  pasteBooking(toPaste: PasteBookingDto[]) {
    //console.log('paste booking');
    return this.http.put(environment.apiUrl + 'bookings/paste/', toPaste, httpOptions).pipe(map((data: any) => data));
  }
  updateBookingWithPeriod(
    bookingId: string,
    roomId: string | null,
    houseId: string | null,
    start: Date,
    end: Date
  ): Observable<any> {
    return this.http
      .put(
        environment.apiUrl + 'bookings/' + bookingId,
        { roomId: roomId, houseId: houseId, period: { start: start, end: end } },
        httpOptions
      )
      .pipe(map((data: any) => data));
  }

  editBooking(booking: BookingDto): Observable<any> {
    return this.http.put(environment.apiUrl + 'bookings', booking, httpOptions).pipe(map((data: any) => data));
  }

  confirmBooking(bookingId: string): Observable<any> {
    return this.http
      .put(environment.apiUrl + 'bookings/' + bookingId + '/confirm', { confirmed: true }, httpOptions)
      .pipe(map((data: any) => data));
  }

  unconfirmBooking(bookingId: string): Observable<any> {
    return this.http
      .put(environment.apiUrl + 'bookings/' + bookingId + '/confirm', { confirmed: false }, httpOptions)
      .pipe(map((data: any) => data));
  }

  clientArrived(bookingId: string): Observable<any> {
    return this.http
      .put(environment.apiUrl + 'bookings/' + bookingId + '/arrived', { arrived: true }, httpOptions)
      .pipe(map((data: any) => data));
  }

  deleteBooking(bookingId: string): Observable<any> {
    return this.http
      .put(environment.apiUrl + 'bookings/' + bookingId, { status: 'DELETED', active: false }, httpOptions)
      .pipe(map((data: any) => data));
  }

  updateBookingPeriod(bookingId: string, start: number, end: number): Observable<any> {
    return this.http
      .patch(
        environment.apiUrl + 'bookings/' + bookingId + '/period',
        {
          period: {
            start,
            end,
          },
        },
        httpOptions
      )
      .pipe(map((data: any) => data));
  }

  updateBookingHouseRoom(bookingId: string, houseId: string, roomId: string): Observable<any> {
    return this.http
      .patch(
        environment.apiUrl + 'bookings/' + bookingId + '/house',
        {
          houseId,
          roomId,
        },
        httpOptions
      )
      .pipe(map((data: any) => data));
  }

  createInvoice(bookingId: string): Observable<any> {
    return this.http
      .post(environment.apiUrl + 'invoices', { bookingId: bookingId }, httpOptions)
      .pipe(map((data: any) => data));
  }

  createPayment(amount: number, clientId: string, bookingId: string, paymentDate: number): Observable<any> {
    return this.http
      .post(
        environment.apiUrl + 'transactions',
        {
          amount: amount,
          invoiceId: null,
          status: 'PAID',
          clientId: clientId,
          bookingId: bookingId,
          contractId: null,
          paymentDate: paymentDate,
        },
        httpOptions
      )
      .pipe(map((data: any) => data));
  }

  createOtherPayment(
    amount: number,
    clientId: string,
    bookingId: string,
    paymentDate: number | null,
    purpose: string,
    category: string,
    isNewPayment: boolean
  ): Observable<any> {
    let status = 'CREATED';

    if (paymentDate && isNewPayment == false) {
      status = 'PAID';
    }
    return this.http
      .post(
        environment.apiUrl + 'transactions/other',
        {
          amount: amount,
          invoiceId: null,
          status: status,
          clientId: clientId,
          bookingId: bookingId,
          contractId: null,
          paymentDate: paymentDate,
          purpose: purpose,
          category: category,
          isNewPayment: isNewPayment,
        },
        httpOptions
      )
      .pipe(map((data: any) => data));
  }

  createBookingByPeriod(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'bookings', data, httpOptions).pipe(map((data: any) => data));
  }

  createBookingByPeriodDaily(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'bookings/daily', data, httpOptions).pipe(map((data: any) => data));
  }

  getWeekList(year: number | string, parseWeekList: boolean = true): Observable<any> {
    let result = this.http
      .get(environment.apiUrl + `bookings/weeks?year=${year}`, httpOptions)
      .pipe(map((data: any) => data));
    if (parseWeekList) {
      result.subscribe((data) => {
        this.parseWeekList(data, year);
      });
    }

    return result;
  }

  parseWeekList(data: any, year: number | string): IYear {
    let iyear: IYear = this.storeService.weeks.value;
    // @ts-ignore
    iyear[year] = [];
    for (let index in data.data) {
      let iweek: IWeek = {};
      // console.log('week', week);
      // @ts-ignore
      if (data.data[index].name !== index) {
        data.data[index].name = index;
      }
      if (typeof data.data[index].name === 'string') {
        data.data[index].name = parseInt(data.data[index].name, 10);
      }
      if (typeof data.data[index].start === 'string') {
        data.data[index].start = parseISO(data.data[index].start);
      }
      if (typeof data.data[index].last === 'string') {
        data.data[index].last = parseISO(data.data[index].last);
      }
      // @ts-ignore
      iweek[index] = data.data[index];
      // @ts-ignore
      iyear[year].push(iweek);
    }
    this.storeService.weeks.next(iyear);
    return iyear;
  }

  getBookingByDate({
    year,
    month = undefined,
    status = 'all',
    history = true,
    type = 'weekly',
    clientId = undefined,
    roomId = undefined,
    houseId = undefined,
    week = undefined,
    roomStatus = 'all',
  }: FilterParams): Observable<any> {
    if (roomStatus === 'free' || roomStatus === 'unpaid') {
      roomStatus = 'all';
    }
    month = 8;
    let url =
      environment.apiUrl + `bookings/shortlist?year=${year}&status=${roomStatus}&history=${history}&type=${type}`;
    // environment.apiUrl + `bookings/shortlist?year=${year}&status=all&history=${history}&type=all`;
    if (month) {
      url = url + `&month=${month}`;
    }
    if (clientId) {
      url = url + `&clientId=${clientId}`;
    }

    if (houseId) {
      url = url + `&houseId=${houseId}`;
    }

    if (roomId) {
      url = url + `&roomId=${roomId}`;
    }
    if (week) {
      url = url + `&week=${week}`;
    }
    return this.http.get(url, httpOptions).pipe(map((data: any) => data));
  }

  getAvailableYearList(): Observable<number[]> {
    let result = this.http.get(environment.apiUrl + `bookings/yearlist`, httpOptions).pipe(map((data: any) => data));
    result.subscribe((data) => {
      this.storeService.availableYearList.next(data);
    });
    return result;
  }
  getBookingByDate2({
    start,
    end,
    status = 'all',
    type = 'weekly',
    houseId = undefined,
    roomStatus = 'all',
  }: FilterParams2): Observable<any> {
    if (roomStatus === 'free' || roomStatus === 'unpaid') {
      roomStatus = 'all';
    }
    let url =
      environment.apiUrl +
      `bookings/shortlist2?start=${start.getTime()}&end=${end.getTime()}&status=${roomStatus}&type=${type}`;

    if (houseId) {
      url = url + `&houseId=${houseId}`;
    }
    // console.warn(url);
    let result = this.http.get(url, httpOptions).pipe(map((data: any) => data));
    result.subscribe((data) => {
      // console.log('got shortlist2 result');
      data.start = parseISO(data.start);
      data.last = parseISO(data.last);
      // console.log(data);
    });
    return result;
  }
}
