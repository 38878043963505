import { Injectable } from '@angular/core';
import { ClientDto } from '../../dto/client.dto';
import { ITranslation } from '../../interfaces/translation.interface';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  public static searchContact(client: ClientDto, term: string, translation: ITranslation): boolean {
    let textResult =
      `${client.prename} ${client.lastname}`.toLowerCase().includes(term.toLowerCase()) ||
      client.displayName?.toLowerCase().includes(term.toLowerCase());
    if (textResult === true) {
      return textResult;
    }
    let phoneResult = false;
    if (client.contacts && client.contacts.phone && client.contacts.phone.length > 0) {
      for (let i = 0; i < client.contacts.phone.length; i++) {
        phoneResult =
          phoneResult || `${client.contacts.phone[i].prefix}${client.contacts.phone[i].number}`.includes(term);
      }
    }
    if (phoneResult === true) {
      return phoneResult;
    }
    let mobileResult = false;
    if (client.contacts && client.contacts.mobile && client.contacts.mobile.length > 0) {
      for (let i = 0; i < client.contacts.mobile.length; i++) {
        mobileResult =
          mobileResult || `${client.contacts.mobile[i].prefix}${client.contacts.mobile[i].number}`.includes(term);
      }
    }
    if (mobileResult === true) {
      return mobileResult;
    }
    let clientNoResult = false;
    if (client.clientNo) {
      clientNoResult = clientNoResult || `${client.clientNo}`.includes(term);
    }
    if (clientNoResult === true) {
      return clientNoResult;
    }
    let clientNoResult2 = false;
    if (client.clientNo) {
      clientNoResult2 = clientNoResult2 || `K${client.clientNo}`.includes(`${term}`);
    }
    if (clientNoResult2 === true) {
      return clientNoResult2;
    }
    let categoryResult = false;
    if (client.category && client.category.length > 0) {
      for (let i = 0; i < client.category.length; i++) {
        categoryResult =
          categoryResult || (client.category[i].checked === true && `${client.category[i].name}`.includes(term));
      }
    }
    if (categoryResult === true) {
      return categoryResult;
    }
    let clientTypeResult = false;

    if (translation[client.clientType?.toUpperCase() || 'UNKOWN'].toLowerCase().includes(term.toLowerCase())) {
      clientTypeResult = clientTypeResult || true;
    }
    return clientTypeResult;
  }
}
