import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ClientDto } from '../../dto/client.dto';
import { ContractTypeDto } from '../../dto/contract.type.dto';
import { DocumentCardDto } from '../../dto/document.card.dto';
import { HistoryDto } from '../../dto/history.dto';
import { InvoiceDto } from '../../dto/invoice.dto';
import { ProveDto } from '../../dto/prove.dto';
import { ISocketUpdate } from '../../interfaces/socket-update.interface';
import { SocketService } from '../socket/socket.service';
import { StoreService } from '../store/store.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  private initalLoad = false;
  private isVisble = false;
  private isFetching = false;

  constructor(private http: HttpClient, private storeService: StoreService, private socketService: SocketService) {
    this.socketService.getMessage('clients').subscribe((message: ISocketUpdate) => {
      if (message.entity === 'clients') {
        if (this.initalLoad && this.isVisble) {
          this.getClients();
        }
      }
    });
    this.socketService.getMessage('messages').subscribe((message: ISocketUpdate) => {
      if (message.entity === 'messages') {
        if (this.initalLoad && this.isVisble) {
          console.log('got message update', message.payload);
          console.dir(message);
          this.getMessages(message.payload);
        }
      }
    });

    this.storeService.appVisible.subscribe((isVisble) => {
      this.isVisble = isVisble;
      if (isVisble && this.initalLoad) {
        this.getClients();
      }
    });
    this.storeService.login.subscribe((login) => {
      if (login) {
        setTimeout(() => {
          this.isFetching = false;
          this.getClients();
          this.initalLoad = true;
        }, 200);
      } else {
        this.initalLoad = false;
      }
    });
  }

  getClients(): Observable<ClientDto[]> {
    // console.log('getClients is called');
    if (!this.isFetching) {
      this.isFetching = true;
      let result = this.http.get(environment.apiUrl + 'clients', httpOptions).pipe(map((data: any) => data));
      result.subscribe((data: ClientDto[]) => {
        // console.log(data);
        data = data.sort((a, b) => {
          if (new Date(a.updatedAt!) < new Date(b.updatedAt!)) {
            return 1;
          }
          return -1;
        });
        this.storeService.clients.next(data);
        this.isFetching = false;
      });
      return result;
    }
    return from([]);
  }

  updatePayment(clientId: string, transactionId: string, amount: number, updatedAt: Date): Observable<any> {
    console.log('update', transactionId, amount, updatedAt);
    return this.http
      .put(
        environment.apiUrl + 'transactions',
        {
          _id: transactionId,
          amount: amount,
          updatedAt: updatedAt,
        },
        httpOptions
      )
      .pipe(map((data: any) => data));
  }

  checkClient(firstName: string, lastName: string): Observable<ClientDto | null> {
    return this.http
      .post(environment.apiUrl + `clients/search`, { prename: firstName, lastname: lastName }, httpOptions)
      .pipe(map((data: any) => data));
  }

  getProves(clientId: string): Observable<ProveDto[]> {
    return this.http.get(environment.apiUrl + `clients/${clientId}/proves`, httpOptions).pipe(map((data: any) => data));
  }

  getClient(id: string): Observable<ClientDto> {
    return this.http.get(environment.apiUrl + 'clients/' + id, httpOptions).pipe(map((data: any) => data));
  }

  createClient(client: any): Observable<ClientDto> {
    return this.http.post(environment.apiUrl + 'clients', client, httpOptions).pipe(map((data: any) => data));
  }

  getMediaImage(id: string): Observable<any> {
    return this.http.get(environment.apiUrl + 'wapi/media/' + id, httpOptions).pipe(map((data: any) => data));
  }

  getMessages(id: string): Observable<any[]> {
    let result = this.http
      .post(environment.apiUrl + 'wapi/messages', { clientId: id }, httpOptions)
      .pipe(map((data: any) => data));
    result.subscribe((data) => {
      this.storeService.messages.next(data);
    });
    return result;
  }

  sentMessage(id: string, message: string): Observable<boolean> {
    let result = this.http
      .post(environment.apiUrl + 'wapi/messages/send', { clientId: id, body: message }, httpOptions)
      .pipe(map((data: any) => data));
    return result;
  }

  uploadClientImage(clientId: string, file: any): Observable<ClientDto> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http
      .post(environment.apiUrl + 'clients/' + clientId + '/image', formData)
      .pipe(map((data: any) => data));
  }

  deleteDocumentImage(
    clientId: string,
    documentType: string,
    documentId: string,
    imageUrl: string
  ): Observable<boolean> {
    const parts = imageUrl.split('/');
    return this.http
      .put(environment.apiUrl + 'clients/' + clientId + '/documents/' + documentId + '/imageremove', {
        imageUrl: parts[parts.length - 1],
      })
      .pipe(map((data: any) => data));
  }

  deleteProveImage(clientId: string, proveId: string) {
    return this.http
      .delete(environment.apiUrl + 'clients/' + clientId + '/proves', {
        body: {
          _id: proveId,
        },
      })
      .pipe(map((data: any) => data));
  }

  rotateDocumentImage(clientId: string, documentId: string, imageUrl: string, toRotate: number) {
    return this.http
      .put(environment.apiUrl + 'clients/' + clientId + '/documents/' + documentId + '/rotate', {
        toRotate: toRotate,
        imageUrl: imageUrl,
      })
      .pipe(map((data: any) => data));
  }

  rotateProveImage(clientId: string, proveId: string, toRotate: number) {
    return this.http
      .put(environment.apiUrl + 'clients/' + clientId + '/proves/rotate', {
        toRotate: toRotate,
        _id: proveId,
      })
      .pipe(map((data: any) => data));
  }

  getPersonalDocuments(id: string): Observable<DocumentCardDto[]> {
    return this.http.get(environment.apiUrl + 'clients/' + id + '/documents').pipe(map((data: any) => data));
  }

  getNewDocument(clientId: string, type: string): Observable<DocumentCardDto> {
    return this.http
      .get(environment.apiUrl + 'clients/' + clientId + '/documents/' + type, httpOptions)
      .pipe(map((data: any) => data));
  }

  getClientHistory(id: string): Observable<HistoryDto[]> {
    return this.http.get(environment.apiUrl + 'clients/' + id + '/bookings').pipe(map((data: any) => data));
  }

  getClientUnpaid(id: string): Observable<HistoryDto[]> {
    return this.http.get(environment.apiUrl + 'clients/' + id + '/unpaid').pipe(map((data: any) => data));
  }

  getClientInvoices(id: string): Observable<InvoiceDto[]> {
    return this.http.get(environment.apiUrl + 'clients/' + id + '/invoices').pipe(map((data: any) => data));
  }

  updateClient(id: string, data: any): Observable<ClientDto> {
    return this.http.put(environment.apiUrl + 'clients/' + id, data, httpOptions).pipe(map((data: any) => data));
  }

  getContractTypes(locale: string): Observable<ContractTypeDto[]> {
    return this.http
      .get(environment.apiUrl + 'contracts/available?language=' + locale, httpOptions)
      .pipe(map((data: any) => data));
  }

  updateDocument(clientId: string, cardId: string, type: string, card: DocumentCardDto): Observable<DocumentCardDto> {
    return this.http
      .put(environment.apiUrl + 'clients/' + clientId + '/documents/' + cardId + '/' + type, card, httpOptions)
      .pipe(map((data: any) => data));
  }

  uploadCardFrontImage(clientId: string, cardId: string, file: File): Observable<DocumentCardDto> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http
      .post(environment.apiUrl + 'clients/' + clientId + '/documents/' + cardId + '/front', formData)
      .pipe(map((data: any) => data));
  }

  uploadCardBacklImage(clientId: string, cardId: string, file: File): Observable<DocumentCardDto> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http
      .post(environment.apiUrl + 'clients/' + clientId + '/documents/' + cardId + '/back', formData)
      .pipe(map((data: any) => data));
  }

  deleteClient(id: string): Observable<any> {
    return this.http.delete(environment.apiUrl + 'clients/' + id, httpOptions).pipe(map((data: any) => data));
  }

  getTransactions(id: string): Observable<any> {
    return this.http
      .get(environment.apiUrl + 'clients/' + id + '/payments', httpOptions)
      .pipe(map((data: any) => data));
  }

  getClientContracts(clientId: string): Observable<any> {
    return this.http
      .get(environment.apiUrl + 'contracts/clients/' + clientId, httpOptions)
      .pipe(map((data: any) => data));
  }

  postNewContract(clientId: string, types: string[], language: string): Observable<any> {
    return this.http
      .post(
        environment.apiUrl + 'contracts/clients',
        {
          clientId,
          houseId: null,
          types,
          language,
        },
        httpOptions
      )
      .pipe(map((data: any) => data));
  }

  postContractSign(contractId: string, file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(environment.apiUrl + 'contracts/' + contractId + '/signature', formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  downloadContractPdf(contractId: string) {
    return this.http.get(environment.apiUrl + 'contracts/' + contractId + '/pdf', { responseType: 'blob' });
  }
}
