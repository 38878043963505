<div class="imageModal">
    <div class="imageMenu">
        <div class="rotateLeftButton">
            <button (click)="rotateLeft()" class="xButton">
                <i-tabler name="rotate-2" class="mr-2 opacity-75 menu-icon"></i-tabler>
            </button>
        </div>
        <div class="rotateRightButton">
            <button (click)="rotateRight()" class="xButton">
                <i-tabler name="rotate-clockwise-2" class="mr-2 opacity-75 menu-icon"></i-tabler>
            </button>
        </div>
        <div class="zoomInButton">
            <button (click)="zoomIn()" class="xButton">
                <i-tabler name="zoom-in" class="mr-2 opacity-75 menu-icon"></i-tabler>
            </button>
        </div>
        <div class="zoomOutButton">
            <button (click)="zoomOut()" class="xButton">
                <i-tabler name="zoom-out" class="mr-2 opacity-75 menu-icon"></i-tabler>
            </button>
        </div>
        <div class="deleteButton">
            <button (click)="deleteImage()" class="xButton" [disabled]="token.getUserReadOnly()">
                <i-tabler name="trash" class="mr-2 opacity-75 menu-icon"></i-tabler>
            </button>
        </div>
        <div class="closeButton">
            <button (click)="closeModal()" class="xButton">
                <i-tabler name="x" class="mr-2 opacity-75 menu-icon"></i-tabler>
            </button>
        </div>
    </div>
    <div class="imageDiv">
        <img [src]="imageUrl" class="fullImage" [ngStyle]="{transform: rotateStr()}"
            [ngClass]="{'image-transform-origin-center': rotation % 360 !== 0}" (click)="closeModal();">
    </div>
    <div class="saveButton">
        <button *ngIf="rotation % 360 !== 0" nz-button nzType="primary" (click)="save()"
            class="primary">Speichern</button>
    </div>
</div>