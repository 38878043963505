import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from '../../core/guards/admin.guard';
import { CashComponent } from './cash/cash.component';
import { DepositComponent } from './deposit/deposit.component';
import { HomeComponent } from './home/home.component';
import { OverviewComponent } from './overview/overview.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      { path: '', component: OverviewComponent },
      { path: 'houses', loadChildren: () => import('./houses/houses.module').then((m) => m.HousesModule) },
      { path: 'cash', component: CashComponent },
      { path: 'deposit', component: DepositComponent },
      { path: 'contacts', loadChildren: () => import('./contact/contact.module').then((m) => m.ContactModule) },
      { path: 'messages', loadChildren: () => import('./messages/messages.module').then((m) => m.MessagesModule) },
      { path: 'dashboard', loadChildren: () => import('./analysis/analysis.module').then((m) => m.AnalysisModule) },
      { path: 'calendar', loadChildren: () => import('./calendar/calendar.module').then((m) => m.CalendarModule) },
      {
        path: 'settings',
        loadChildren: () => import('./admin-settings/admin-settings.module').then((m) => m.AdminSettingsModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
