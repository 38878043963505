import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { DndDropEvent } from 'ngx-drag-drop';
import { ClientDto } from 'src/app/core/dto/client.dto';
import { HouseDto } from 'src/app/core/dto/house.dto';
import { RoomDto } from 'src/app/core/dto/room.dto';
import { ITranslation } from 'src/app/core/interfaces/translation.interface';
import { BookingService } from 'src/app/core/services/booking/booking.service';
import { TokenStorageService } from 'src/app/core/services/token/token-storage.service';
import { TranslationService } from 'src/app/core/services/translation/translation.service';

@Component({
  selector: 'overview-item-box',
  templateUrl: './overview-item.component.html',
  styleUrls: ['./overview-item.component.css'],
})
export class OverviewItemComponent implements OnInit, OnDestroy {
  @Input() house?: HouseDto;
  @Input() houses?: HouseDto[];
  @Input() clients?: ClientDto[];
  @Output() update = new EventEmitter();

  isSubmitting = false;
  selectedBookedRoom?: RoomDto;

  hiddenCoverPhoto: boolean = true;
  houseInfo?: string;
  translation: ITranslation = {};
  private subscriptions: any[] = [];

  constructor(
    private bookingService: BookingService,
    private router: Router,
    private modal: NzModalService,
    private notification: NzNotificationService,
    public token: TokenStorageService,
    private translationService: TranslationService
  ) {
    this.subscriptions.push(
      this.translationService.translations.subscribe((translation) => {
        this.translation = translation;
      })
    );
  }

  ngOnDestroy(): void {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.houseInfo = `${this.translation['TOTAL_ROOMS']}: ${this.house?.rooms?.length}, ${this.translation['FREE_ROOMS']}: ${this.house?.freeRooms}, ${this.translation['BOOKED_ROOMS']}: ${this.house?.bookedRooms}`;
  }

  hiddenCover(): void {
    this.hiddenCoverPhoto = !this.hiddenCoverPhoto;
  }

  get freeRooms(): RoomDto[] {
    let rooms: RoomDto[] = [];
    if (this.house?.rooms && this.house.rooms.length) {
      rooms = this.house.rooms.filter((e) => e.booking?.status?.toLocaleLowerCase() == 'free');
    }
    return rooms;
  }

  get bookedRooms(): RoomDto[] {
    let rooms: RoomDto[] = [];
    if (this.house?.rooms && this.house.rooms.length) {
      rooms = this.house.rooms.filter((e) => e.booking?.status?.toLocaleLowerCase() == 'booked');
    }
    return rooms;
  }

  get notAllocatedClients(): ClientDto[] {
    let clients: ClientDto[] = [];
    if (this.house?.clients && this.house.clients.length && this.house.rooms && this.house.rooms.length) {
      clients = this.house.clients;
      for (let i = 0; i < this.house.rooms.length; i++) {
        clients = clients.filter((e) => e._id != this.house?.rooms![i].client?._id);
      }
    }
    return clients;
  }

  get dailyPrices(): any[] {
    let prices: any[] = [];
    if (this.house?.prices?.daily) {
      prices.push({
        day: this.translation['MON'],
        price: this.house.prices.daily.monday,
      });
      prices.push({
        day: this.translation['TUE'],
        price: this.house.prices.daily.tuesday,
      });
      prices.push({
        day: this.translation['WED'],
        price: this.house.prices.daily.wednesday,
      });
      prices.push({
        day: this.translation['THU'],
        price: this.house.prices.daily.thursday,
      });
      prices.push({
        day: this.translation['FRI'],
        price: this.house.prices.daily.friday,
      });
      prices.push({
        day: this.translation['SAT'],
        price: this.house.prices.daily.saturday,
      });
      prices.push({
        day: this.translation['SUN'],
        price: this.house.prices.daily.sunday,
      });
    }
    return prices;
  }

  onDrop(event: DndDropEvent, freeRoom: RoomDto) {
    if (event.type == 'booked') {
      this.bookingService.updateBooking(event.data.booking._id, freeRoom._id, this.house?._id!).subscribe({
        next: () => {
          this.update.emit();
        },
      });
    } else {
      this.bookingService.updateBooking(event.data.bookingId, freeRoom._id, this.house?._id!).subscribe({
        next: () => {
          this.update.emit();
        },
      });
    }
  }

  onClickBookedRoom(e: MouseEvent, room: RoomDto): void {
    e.stopPropagation();
    this.selectedBookedRoom = room;
  }

  onClickFreeRoom(e: MouseEvent, room: RoomDto, tplContent: TemplateRef<{}>): void {
    e.stopPropagation();
    if (this.house?._id != null && room._id != null) {
      this.createBook(room, tplContent);
    }
  }

  newBookingModal?: NzModalRef;

  createBook(room: RoomDto, tplContent: TemplateRef<{}>) {
    const house = this.houses?.find((e) => e._id == this.house?._id);
    const form = new UntypedFormGroup({
      price: new UntypedFormControl('', [Validators.required]),
      notes: new UntypedFormControl('', [Validators.required]),
      fixedRoom: new UntypedFormControl(false, [Validators.required]),
      selectedClients: new UntypedFormControl([], [Validators.required]),
      period: new UntypedFormControl([], [Validators.required]),
      client: new UntypedFormControl('', [Validators.required]),
    });

    let freeRooms: RoomDto[] = [];
    if (house?.rooms && house.rooms.length) {
      freeRooms = house.rooms.filter((e) => e._id == room?._id);
    }

    const today = new Date();
    const first = today.getDate() - today.getDay() + 1;
    const sixth = first + 5;
    const saturday = new Date(today.setDate(sixth));

    form.patchValue({
      period: [new Date(), saturday],
    });

    const totalPrice =
      room?.prices?.daily?.monday! +
      room?.prices?.daily?.tuesday! +
      room!.prices?.daily?.wednesday! +
      room?.prices?.daily?.thursday! +
      room?.prices?.daily?.friday! +
      room?.prices?.daily?.saturday! +
      room?.prices?.daily?.sunday!;
    if (room?.type == 'daily') {
      form.patchValue({
        price: totalPrice.toFixed(2),
      });
    } else {
      if ((new Date().getDay() == 0 && saturday.getDay() == 6) || new Date().getDay() - saturday.getDay() == 1) {
        form.patchValue({
          price: room?.prices?.weekly?.toFixed(2),
        });
      } else {
        form.patchValue({
          price: (totalPrice / 7).toFixed(2),
        });
      }
    }

    this.newBookingModal = this.modal.create({
      nzTitle: this.translation['CREATE_BOOKING'],
      nzContent: tplContent,
      nzCentered: true,
      nzWidth: 600,
      nzFooter: null,
      nzData: {
        house: house,
        room: room,
        form: form,
        filterClients: (value: string, option: any) => {
          const sc = this.clients!.find((e) => e._id === option.nzValue);
          let textResult =
            `${sc!.prename} ${sc!.lastname}`.toLowerCase().includes(value.toLowerCase()) ||
            sc!.displayName?.toLowerCase().includes(value.toLowerCase());
          let phoneResult = false;
          if (sc!.contacts && sc!.contacts.phone && sc!.contacts.phone.length > 0) {
            for (let i = 0; i < sc!.contacts.phone.length; i++) {
              phoneResult =
                phoneResult || `${sc!.contacts.phone[i].prefix}${sc!.contacts.phone[i].number}`.includes(value);
            }
          }
          return textResult || phoneResult;
        },
        submit: () => {
          if (form.value.client.length > 0) {
            this.isSubmitting = true;
            const booking = {
              houseId: house?._id,
              clientId: form.value.client,
              notes: form.value.notes,
              bookingId: freeRooms[0].booking?._id,
              fixedRoom: form.value.fixedRoom,
              roomId: room?._id,
              period: {
                start: new Date(form.value.period[0]).getTime(),
                end: new Date(form.value.period[1]).getTime(),
              },
              taxRate: freeRooms[0].booking?.taxRate,
              total: Number(form.value.price),
            };

            this.bookingService.createBookingByPeriod(booking).subscribe({
              next: () => {
                this.isSubmitting = false;
                this.newBookingModal?.close();
                this.update.emit();
              },
            });
          }
        },
      },
      nzOkDisabled: true,
    });
  }

  cancelBooking(): void {
    if (this.selectedBookedRoom?.booking) {
      this.bookingService.cancelBooking(this.selectedBookedRoom.booking._id).subscribe({
        next: () => {
          this.update.emit();
        },
      });
    }
  }

  removeRoomFromBooking(): void {
    if (this.selectedBookedRoom?.booking) {
      this.bookingService.updateBooking(this.selectedBookedRoom.booking._id, null, this.house?._id!).subscribe({
        next: () => {
          this.update.emit();
        },
      });
    }
  }

  createInvoice(): void {
    if (this.selectedBookedRoom?.booking) {
      this.bookingService.createInvoice(this.selectedBookedRoom.booking._id).subscribe({
        next: () => {
          this.update.emit();
        },
      });
    }
  }

  viewClient(): void {
    if (this.selectedBookedRoom?.booking?.clientId) {
      this.router.navigate([
        this.translationService.getLanguage(),
        'contacts',
        this.selectedBookedRoom?.booking?.clientId,
      ]);
    }
  }

  editBookingModal?: NzModalRef;

  editBooking(tplContent: TemplateRef<{}>): void {
    let form = new UntypedFormGroup({
      client: new UntypedFormControl('', [Validators.required]),
      total: new UntypedFormControl('', [Validators.required]),
      fixedRoom: new UntypedFormControl(false, [Validators.required]),
      period: new UntypedFormControl([], [Validators.required]),
      periodEnd: new UntypedFormControl(new Date(), [Validators.required]),
      notes: new UntypedFormControl('', [Validators.required]),
    });
    form.patchValue({
      client: this.selectedBookedRoom?.booking?.clientId,
      total: this.selectedBookedRoom?.booking?.total?.toFixed(2),
      notes: this.selectedBookedRoom?.booking?.notes,
      fixedRoom: this.selectedBookedRoom?.booking?.fixedRoom,
    });

    const isStarted = new Date() >= new Date(this.selectedBookedRoom?.booking?.period?.start!);

    if (isStarted) {
      form.patchValue({
        periodEnd: new Date(this.selectedBookedRoom?.booking?.period?.end!),
      });
    } else {
      form.patchValue({
        period: [
          new Date(this.selectedBookedRoom?.booking?.period?.start!),
          new Date(this.selectedBookedRoom?.booking?.period?.end!),
        ],
      });
    }

    let oldPrice = null;
    if (localStorage.getItem(this.selectedBookedRoom?._id!)) {
      oldPrice = localStorage.getItem(this.selectedBookedRoom?._id!);
    }

    this.editBookingModal = this.modal.create({
      nzTitle: this.translation['EDIT_BOOKING'],
      nzContent: tplContent,
      nzWidth: 600,
      nzData: {
        house: this.house,
        room: this.selectedBookedRoom,
        oldPrice: oldPrice,
        isStarted: isStarted,
        form: form,
        filterClients: (value: string, option: any) => {
          const sc = this.clients!.find((e) => e._id === option.nzValue);
          let textResult =
            `${sc!.prename} ${sc!.lastname}`.toLowerCase().includes(value.toLowerCase()) ||
            sc!.displayName?.toLowerCase().includes(value.toLowerCase());
          let phoneResult = false;
          if (sc!.contacts && sc!.contacts.phone && sc!.contacts.phone.length > 0) {
            for (let i = 0; i < sc!.contacts.phone.length; i++) {
              phoneResult =
                phoneResult || `${sc!.contacts.phone[i].prefix}${sc!.contacts.phone[i].number}`.includes(value);
            }
          }
          return textResult || phoneResult;
        },
        submit: () => {
          if (this.selectedBookedRoom?.booking) {
            this.isSubmitting = true;
            let updatedBooking = this.selectedBookedRoom.booking;
            updatedBooking.clientId = form.value.client;
            updatedBooking.roomId = this.selectedBookedRoom._id;
            updatedBooking.fixedRoom = form.value.fixedRoom;
            (updatedBooking.period = {
              start: isStarted ? new Date(updatedBooking.period?.start!).getTime() : form.value.period[0].getTime(),
              end: isStarted ? form.value.periodEnd.getTime() : form.value.period[1].getTime(),
            }),
              (updatedBooking.notes = form.value.notes);
            updatedBooking.total = Number(Number(form.value.total).toFixed(2));
            updatedBooking.client = this.clients?.find((e) => e._id == form.value.client);

            localStorage.setItem(this.selectedBookedRoom?._id!, this.selectedBookedRoom?.booking?.total?.toFixed(2)!);

            this.bookingService.editBooking(updatedBooking).subscribe({
              next: () => {
                this.isSubmitting = false;
                this.editBookingModal?.close();
                this.notification.success(this.translation['BOOKING'], this.translation['UPDATE_BOOKING_SUCCESS']);
              },
            });
          }
        },
      },
      nzFooter: null,
    });
  }

  paymentModal?: NzModalRef;
  createPayment(content: TemplateRef<{}>) {
    let form = new UntypedFormGroup({
      amount: new UntypedFormControl('', [Validators.required]),
      paymentDate: new UntypedFormControl(null, [Validators.required]),
    });
    this.paymentModal = this.modal.create({
      nzTitle: this.translation['NEW_PAYMENT'],
      nzWidth: 600,
      nzContent: content,
      nzData: {
        house: this.house,
        room: this.selectedBookedRoom,
        form: form,
        submit: () => {
          this.isSubmitting = true;
          this.bookingService
            .createPayment(
              Number(form.value.amount),
              this.selectedBookedRoom?.booking?.client?._id!,
              this.selectedBookedRoom?.booking?._id!,
              form.value.paymentDate.getTime()
            )
            .subscribe({
              next: () => {
                this.paymentModal?.close();
                this.notification.success(this.translation['PAYMENT'], this.translation['PAYMENT_CREATION_SUCCESS']);
              },
            });
        },
      },
      nzFooter: null,
    });
  }
}
