<div class="relative z-10" role="dialog" aria-modal="true" *ngIf="open">

  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" @backdropFade></div>

  <div class="fixed inset-0 overflow-hidden">
    <div class="absolute inset-0 overflow-hidden">
      <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">

        <div class="pointer-events-auto w-screen max-w-md" @fade>
          <div class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
            <div class="flex-1 overflow-y-auto py-6 px-4 sm:px-6">
              <div class="flex items-start justify-end">
                <div class="ml-3 flex h-7 items-center">
                  <button type="button" class="-m-2 p-2 text-gray-400 hover:text-gray-500" (click)="clickClose($event)">
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                      stroke-width="2" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>

              <div class="mt-8">
                <div class="flow-root">
                  <ul role="list" class="-my-6 divide-y divide-gray-200">
                    <li class="flex py-6">
                      <div class="ml-4 flex flex-1 flex-col">
                        <div>
                          <div class="flex justify-between text-base font-medium text-gray-900">
                            <h3>
                              <a href="#"> House </a>
                            </h3>
                          </div>
                          <p class="mt-1 text-sm text-gray-500">{{ history?.house?.name }}</p>
                        </div>
                        <div class="flex flex-1 items-end justify-between text-sm">
                          <p class="text-gray-500">{{ history?.house?.address?.country }} {{
                            history?.house?.address?.city }} {{ history?.house?.address?.street }}</p>
                        </div>
                      </div>
                    </li>

                    <li class="flex py-6" *ngIf="history?.room">
                      <div class="ml-4 flex flex-1 flex-col">
                        <div>
                          <div class="flex justify-between text-base font-medium text-gray-900">
                            <h3>
                              <a href="#"> Room </a>
                            </h3>

                          </div>
                          <div class="flex items-center">
                            <dt class="text-sm font-medium text-gray-500 rounded-md px-2 py-1 mr-2 bg-violet-100">
                              {{history?.room?.roomNumber}}</dt>
                            <p class="mt-1 text-sm text-gray-500">{{ history?.room?.name }}</p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="flex py-6" *ngIf="history?.client">
                      <div class="ml-4 flex flex-1 flex-col">
                        <div>
                          <div class="flex justify-between text-base font-medium text-gray-900">
                            <h3>
                              <a href="#"> Client </a>
                            </h3>

                          </div>
                          <div class="flex items-center">
                            <img [src]="history?.client?.thumb ? history?.client?.thumb : clientPlaceHolder"
                              class="w-10 h-10 rounded-full" />
                            <div class="flex-1 flex justify-between items-center pl-2">
                              <div class="flex flex-col items-start">
                                <p class="mt-1 text-sm text-gray-500">{{ history?.client?.displayName }} {{
                                  history?.client?.clientNo }}</p>
                                <p class="text-sm text-gray-500">{{ history?.client?.prename }} {{
                                  history?.client?.lastname }}</p>
                              </div>
                              <div *ngIf="history?.client?.status" class="mr-4">
                                <i-tabler name="shield-check"
                                  *ngIf="history?.client?.status?.toLocaleLowerCase() === 'ok'"
                                  [style]="getColorString('clientOK')"></i-tabler>
                                <i-tabler name="circle-off"
                                  *ngIf="history?.client?.status?.toLocaleLowerCase() === 'missing_information'"
                                  [style]="getColorString('clientMissingInformation')"></i-tabler>
                                <i-tabler name="alert-triangle"
                                  *ngIf="history?.client?.status?.toLocaleLowerCase() === 'invalid_soon'"
                                  [style]="getColorString('clientInvalidSoon')"></i-tabler>
                                <i-tabler name="alert-circle"
                                  *ngIf="history?.client?.status?.toLocaleLowerCase() === 'invalid'"
                                  [style]="getColorString('clientInvalid')"></i-tabler>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="flex py-6" *ngIf="history?.period">
                      <div class="ml-4 flex flex-1 flex-col">
                        <div>
                          <div class="flex justify-between text-base font-medium text-gray-900">
                            <h3>
                              <a href="#"> Booking period </a>
                            </h3>

                          </div>
                          <div class="flex items-center justify-between">
                            <div>
                              {{ getDateString(history?.period?.start) }}
                            </div>
                            <div>
                              {{ getDateString(history?.period?.end) }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="flex py-6">
                      <div class="ml-4 flex flex-1 flex-col">
                        <div>
                          <div class="flex justify-between text-base font-medium text-gray-900">
                            <h3>
                              <a href="#"> Price </a>
                            </h3>

                          </div>
                          <div class="flex items-center justify-between">
                            <div>
                              {{ priceLabel }}
                            </div>
                            <div>
                              {{ price }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="flex py-6" *ngIf="history?.notes">
                      <div class="ml-4 flex flex-1 flex-col">
                        <div>
                          <div class="flex justify-between text-base font-medium text-gray-900">
                            <h3>
                              <a href="#"> Notes </a>
                            </h3>

                          </div>
                          <div class="flex items-center justify-between">
                            <div>
                              {{ history?.notes }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>