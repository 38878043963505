import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { HouseDto } from 'src/app/core/dto/house.dto';
import { ITranslation } from 'src/app/core/interfaces/translation.interface';
import { TranslationService } from 'src/app/core/services/translation/translation.service';

@Component({
  selector: 'house-box',
  templateUrl: './house.component.html',
  styleUrls: ['./house.component.css'],
})
export class HouseComponent implements OnInit, OnDestroy {
  @Input() house?: HouseDto;
  @Output() click = new EventEmitter();

  translation: ITranslation = {};
  private subscriptions: any[] = [];

  constructor(private translationService: TranslationService) {
    this.subscriptions.push(
      this.translationService.translations.subscribe((translation) => {
        this.translation = translation;
      })
    );
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  ngOnInit(): void {}

  onClick(e: MouseEvent): void {
    e.stopPropagation();
    this.click.emit();
  }
}
