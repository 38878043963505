import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { ITranslation } from 'src/app/core/interfaces/translation.interface';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { StoreService } from 'src/app/core/services/store/store.service';
import { TokenStorageService } from 'src/app/core/services/token/token-storage.service';
import { TranslationService } from 'src/app/core/services/translation/translation.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, OnDestroy {
  form = new UntypedFormGroup({
    username: new UntypedFormControl('', [Validators.required]),
    password: new UntypedFormControl('', [Validators.required]),
  });
  isSubmitting: boolean = false;
  rememberMe = false;

  subscriptions: any[] = [];
  translation: ITranslation = {};
  constructor(
    private authService: AuthService,
    private tokenService: TokenStorageService,
    private router: Router,
    private translationService: TranslationService,
    private storeService: StoreService
  ) {
    this.subscriptions.push(
      this.translationService.translations.subscribe((translation) => {
        this.translation = translation;
      })
    );
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    var rm = window.localStorage.getItem('cashsystem_admin_v2_remember_me');
    if (rm != null && rm == 'true') {
      this.rememberMe = true;
      this.decryptoUserCredential();
    } else {
      this.form.patchValue({
        username: '',
        password: '',
      });
    }
  }

  submit(): void {
    this.saveUserCredential();
    this.isSubmitting = true;
    this.authService.login(this.form.value.username, this.form.value.password).subscribe({
      next: (data) => {
        this.isSubmitting = false;
        this.tokenService.saveUser(JSON.stringify(data));
        this.tokenService.saveToken(data.accessToken);
        this.storeService.history.next([`${this.translationService.getLanguage()}/calendar`]);
        this.router.navigate([this.translationService.getLanguage(), 'calendar']);
        this.storeService.appVisible.next(true);
        this.storeService.login.next(true);
      },
    });
  }

  onChangeRemember(e: any) {
    this.rememberMe = e.target.checked;
  }

  saveUserCredential() {
    if (this.rememberMe) {
      window.localStorage.setItem('cashsystem_admin_v2_remember_me', 'true');
      window.localStorage.setItem('cashsystem_admin_v2_user_name', this.form.value.username);
      var encrypted = CryptoJS.AES.encrypt(this.form.value.password, this.form.value.username).toString();

      window.localStorage.setItem('cashsystem_admin_v2_password', encrypted);
    } else {
      window.localStorage.setItem('cashsystem_admin_v2_remember_me', 'false');
    }
  }

  decryptoUserCredential() {
    const username = window.localStorage.getItem('cashsystem_admin_v2_user_name');
    const encrypted = window.localStorage.getItem('cashsystem_admin_v2_password');
    if (username != null && encrypted != null) {
      var decrypted = CryptoJS.AES.decrypt(encrypted, username);
      this.form.patchValue({
        username: username,
        password: decrypted.toString(CryptoJS.enc.Utf8),
      });
    }
  }
}
