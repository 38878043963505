import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TablerIconsModule } from 'angular-tabler-icons';
import {
  IconAlertCircle,
  IconAlertTriangle,
  IconBox,
  IconBoxMultiple,
  IconBoxMultiple1,
  IconBoxMultiple2,
  IconBoxMultiple3,
  IconBoxMultiple4,
  IconBoxMultiple5,
  IconBoxMultiple6,
  IconBoxMultiple7,
  IconBrandWhatsapp,
  IconCalendarStats,
  IconCash,
  IconChalkboard,
  IconCheck,
  IconChevronUp,
  IconCircleCheck,
  IconCircleMinus,
  IconCircleOff,
  IconCirclePlus,
  IconCloudUpload,
  IconCoin,
  IconCreditCard,
  IconCurrencyDollar,
  IconDeviceMobileMessage,
  IconEdit,
  IconEditCircle,
  IconExclamationMark,
  IconFileInfo,
  IconFileInvoice,
  IconFileText,
  IconFlame,
  IconHistory,
  IconHome2,
  IconLayoutDashboard,
  IconLayoutList,
  IconLogout,
  IconMenu2,
  IconMessage,
  IconMessage2,
  IconMessages,
  IconMoodNeutral,
  IconMoodSad,
  IconMoodSmile,
  IconPencilMinus,
  IconPhone,
  IconPin,
  IconPlus,
  IconPrinter,
  IconRotate2,
  IconRotateClockwise2,
  IconSearch,
  IconSend,
  IconSettings,
  IconShieldCheck,
  IconSignature,
  IconSitemap,
  IconSquareX,
  IconTag,
  IconTrash,
  IconUpload,
  IconUser,
  IconUserCheck,
  IconUserCircle,
  IconUsers,
  IconX,
  IconZoomIn,
  IconZoomOut,
} from 'angular-tabler-icons/icons';

const icons = {
  IconAlertCircle,
  IconMoodSmile,
  IconMoodSad,
  IconMoodNeutral,
  IconCircleCheck,
  IconCircleOff,
  IconShieldCheck,
  IconAlertTriangle,
  IconPhone,
  IconEdit,
  IconCirclePlus,
  IconCircleMinus,
  IconFlame,
  IconSitemap,
  IconPlus,
  IconBoxMultiple,
  IconBoxMultiple1,
  IconBoxMultiple2,
  IconBoxMultiple3,
  IconBoxMultiple4,
  IconBoxMultiple5,
  IconBoxMultiple6,
  IconBoxMultiple7,
  IconCurrencyDollar,
  IconX,
  IconPin,
  IconHome2,
  IconTag,
  IconCalendarStats,
  IconBox,
  IconUser,
  IconBrandWhatsapp,
  IconMenu2,
  IconLayoutDashboard,
  IconCash,
  IconUsers,
  IconSearch,
  IconUpload,
  IconEditCircle,
  IconTrash,
  IconChevronUp,
  IconLayoutList,
  IconChalkboard,
  IconPencilMinus,
  IconFileText,
  IconSignature,
  IconHistory,
  IconFileInvoice,
  IconFileInfo,
  IconCloudUpload,
  IconCreditCard,
  IconSettings,
  IconUserCheck,
  IconMessage2,
  IconMessage,
  IconDeviceMobileMessage,
  IconUserCircle,
  IconPrinter,
  IconLogout,
  IconSquareX,
  IconRotate2,
  IconRotateClockwise2,
  IconZoomIn,
  IconZoomOut,
  IconSend,
  IconCheck,
  IconExclamationMark,
  IconCoin,
  IconMessages,
};

@NgModule({
  declarations: [],
  imports: [CommonModule, TablerIconsModule.pick(icons)],
  exports: [TablerIconsModule],
})
export class IconsModule {}
