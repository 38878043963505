<nav [ngClass]="{'md:w-72': !isCollapsed, 'px-6': !isCollapsed, 'px-2':isCollapsed, 'md:w-12': isCollapsed}"
  class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-72 z-10 py-4 ">
  <div
    class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">

    <div class="flex">
      <button [ngClass]="{'px-2': !isCollapsed}"
        class="cursor-pointer text-black opacity-50 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
        type="button" (click)="toggleCollapseShow('hide'); toggle('mobile-hidden')">
        <i-tabler name="menu-2"></i-tabler>
      </button>
      <a [ngClass]="{'hide': isCollapsed}"
        class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
        href="javascript:void(0)">
        Intim Trier App
      </a>

    </div>
    <span style="font-size: 0.7em;" [ngClass]="{'hide': isCollapsed}">{{version}}</span>
    <div
      class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded mobile-mt-40"
      [ngClass]="getCol2()">
      <!--[ngClass]="collapseShow"-->
      <!-- Navigation -->
      <ul class="md:flex-col md:min-w-full flex flex-col list-none mobile-sidenav" [ngClass]="getCol2()">
        <li class="items-center">
          <button class="text-xs uppercase py-3 font-bold flex items-center" type="button"
            [ngClass]="pageIndex==0? 'text-[#1890ff]':'text-blueGray-700'" (click)="clickOverview()">
            <i-tabler name="layout-dashboard" class="mr-2 opacity-75" style="width: 20px;height: 20px;"></i-tabler>
            <span [ngClass]="{'hide': isCollapsed}">{{translations['OVERVIEW']}}</span>
          </button>
        </li>
        <li class="items-center">
          <button class="hover:text-blueGray-500 text-xs uppercase py-3 font-bold flex items-center" type="button"
            [ngClass]="pageIndex==3 ? 'text-[#1890ff]':'text-blueGray-700'" (click)="clickBookings()">
            <i-tabler name="layout-list" class="mr-2 opacity-75" style="width: 20px;height:20px;"></i-tabler>
            <span [ngClass]="{'hide': isCollapsed}">{{translations['ROOM_PLAN']}}</span>
          </button>
        </li>
        <li class="items-center">
          <button class="text-xs uppercase py-3 font-bold flex items-center" type="button"
            [ngClass]="pageIndex==4 ? 'text-[#1890ff]':'text-blueGray-700'" (click)="clickContacts()">
            <i-tabler name="users" class="mr-2 opacity-75" style="width:20px;height:20px;"></i-tabler>
            <span [ngClass]="{'hide': isCollapsed}">{{translations['CONTACTS']}}</span>
          </button>
        </li>
        <li class="items-center">
          <button class="hover:text-blueGray-500 text-xs uppercase py-3 font-bold flex items-center text-blueGray-700"
            type="button" (click)="toggleSub('financial')">
            <i-tabler name="cash" class="mr-2 opacity-75" style="width:20px;height:20px;"></i-tabler>
            <span [ngClass]="{'hide': isCollapsed}">{{translations['FINANCIAL']}}</span>
          </button>
          <ul id="financial" class="md:flex-col md:min-w-full flex flex-col list-none mobile-sidenav sub-hidden"
            [ngClass]="getCol2()">
            <button class="hover:text-blueGray-500 text-xs uppercase py-3 font-bold flex items-center" type="button"
              [ngClass]="pageIndex==2 ? 'text-[#1890ff]':'text-blueGray-700'" (click)="clickCash()">
              <i-tabler name="cash" class="mr-2 opacity-75" style="width:20px;height:20px;"></i-tabler>
              <span [ngClass]="{'hide': isCollapsed}">{{translations['CASH']}}</span>
            </button>
            <li class="items-center">
              <button class="hover:text-blueGray-500 text-xs uppercase py-3 font-bold flex items-center" type="button"
                [ngClass]="pageIndex==7 ? 'text-[#1890ff]':'text-blueGray-700'" (click)="clickDeposit()">
                <i-tabler name="coin" class="mr-2 opacity-75" style="width:20px;height:20px;"></i-tabler>
                <span [ngClass]="{'hide': isCollapsed}">{{translations['DEPOSIT']}}</span>
              </button>
            </li>
          </ul>
        </li>
        <li class="items-center">
          <button class="text-xs uppercase py-3 font-bold flex items-center" type="button"
            [ngClass]="pageIndex==8 ? 'text-[#1890ff]':'text-blueGray-700'" (click)="clickMessages()">
            <i-tabler name="messages" class="mr-2 opacity-75" style="width:20px;height:20px;"></i-tabler>
            <span [ngClass]="{'hide': isCollapsed}">{{translations['MESSAGES']}}</span>
          </button>
        </li>
        <li class="items-center">
          <button class="text-xs uppercase py-3 font-bold flex items-center" type="button"
            [ngClass]="pageIndex==9? 'text-[#1890ff]':'text-blueGray-700'" (click)="clickAnalysis()">
            <i-tabler name="chalkboard" class="mr-2 opacity-75" style="width: 20px;height: 20px;"></i-tabler>
            <span [ngClass]="{'hide': isCollapsed}">{{translations['REPORTS']}}</span>
          </button>
        </li>

        <li class="items-center" *ngIf="isAdmin()">
          <button class="hover:text-blueGray-500 text-xs uppercase py-3 font-bold flex items-center text-blueGray-700"
            type="button" (click)="toggleSub('settings')">
            <i-tabler name="settings" class="mr-2 opacity-75" style="width: 20px;height:20px;"></i-tabler>
            <span [ngClass]="{'hide': isCollapsed}">{{translations['SETTINGS']}}</span>
          </button>
          <ul id="settings" class="md:flex-col md:min-w-full flex flex-col list-none mobile-sidenav sub-hidden"
            [ngClass]="getCol2()">
            <li class="items-center">
              <button class="hover:text-blueGray-500 text-xs uppercase py-3 font-bold flex items-center" type="button"
                [ngClass]="pageIndex==5 ? 'text-[#1890ff]':'text-blueGray-700'" (click)="clickSettings()">
                <i-tabler name="settings" class="mr-2 opacity-75" style="width: 20px;height:20px;"></i-tabler>
                <span [ngClass]="{'hide': isCollapsed}">{{translations['TEMPLATES']}}</span>
              </button>
            </li>
            <li class="items-center">
              <button class="hover:text-blueGray-500 text-xs uppercase py-3 font-bold flex items-center" type="button"
                [ngClass]="pageIndex==1 ? 'text-[#1890ff]':'text-blueGray-700'" (click)="clickHouses()">
                <i-tabler name="home-2" class="mr-2 opacity-75" style="width: 20px;height:20px;"></i-tabler>
                <span [ngClass]="{'hide': isCollapsed}">{{translations['HOUSES']}}</span>
              </button>
            </li>
            <li class="items-center">
              <button class="hover:text-blueGray-500 text-xs uppercase py-3 font-bold flex items-center" type="button"
                [ngClass]="pageIndex==6 ? 'text-[#1890ff]':'text-blueGray-700'" (click)="clickProfile()">
                <i-tabler name="user-circle" class="mr-2 opacity-75" style="width: 20px;height:20px;"></i-tabler>
                <span [ngClass]="{'hide': isCollapsed}">{{translations['PROFILE']}}</span>
              </button>
            </li>
          </ul>
        </li>

        <li class="items-center">
          <button class="hover:text-blueGray-500 text-xs uppercase py-3 font-bold flex items-center" type="button"
            (click)="logout()">
            <i-tabler name="logout" class="mr-2 opacity-75" style="width: 20px;height:20px;"></i-tabler>
            <span [ngClass]="{'hide': isCollapsed}">{{translations['LOGOUT']}}</span>
          </button>
        </li>
      </ul>
      <!-- Divider -->
      <hr class="my-4 md:min-w-full" [ngClass]="{'hide': isCollapsed}" />
      <!-- Heading -->
      <div *ngIf="isLoading" class="w-full flex justify-center " [ngClass]="{'hide': isCollapsed}">
        <nz-spin nzSimple></nz-spin>
      </div>
      <ul *ngIf="!isLoading" class="md:flex-col md:min-w-full flex flex-col list-none md:mb-4 mobile-hidden"
        [ngClass]="{'hide': isCollapsed}">
        <input [ngModel]="term" (ngModelChange)="changeTerm($event)" nz-input type="text"
          [placeholder]="translations['SEARCH_CLIENT']" class="mb-2">
        <li class="inline-flex mb-3 cursor-pointer" *ngFor="let data of getShortListClients()"
          (click)="onClickContact(data)" [dndDraggable]="data" dndType="client" dndEffectAllowed="all"
          dndDraggingClass="draggable" dndDraggingSourceClass="draggableSource"
          [dndDisableDragIf]="tokenService.getUserReadOnly()">
          <div class="flex items-center" [ngClass]="{'unreliable': data.unreliable === true}">
            <nz-avatar nzSize="small" class="mr-2" [nzSrc]="data.thumb!"
              [nzText]="getUserNameLetters(data)"></nz-avatar>
            <span class="mr-1 text-xs">{{ data.prename }} {{ data.lastname }} ({{data.clientNo}})</span>
            <div>
              <nz-tag nzColor="purple" class="text-xs">
                &#64;{{data.displayName}}
              </nz-tag>
              <nz-tag nzColor="orange" class="text-xs" *ngIf="getCategories(data.category || []) !== ''">
                {{getCategories(data.category || [])}}
              </nz-tag>
              <nz-tag *ngIf="data.clientType && data.clientType !== ''" class="text-xs"
                [nzColor]="getClientTypeColor(data.clientType)">
                {{translations[data.clientType.toUpperCase()]}}
              </nz-tag>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>