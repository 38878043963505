<div class="flex flex-col py-10">
  <div class="flex flex-col md:flex-row md:justify-between items-start md:items-center">
    <table-column-filter [fields]="getTableFields()" (update)="selectColumns($event)"></table-column-filter>
  </div>
</div>
<div class="flex flex-col py-10">
  <nz-table #table [nzData]="deposits" nzSize="small" [nzPageSizeOptions]="[5, 10, 25, 75, 100]"
    [nzShowSizeChanger]="true" nzPaginationPosition="top" [nzPageSize]="pageSize" class="table-scroll">
    <thead>
      <tr class="text-base">
        <!--<th [ngClass]="{'hide-table-column': !showColumn('USER')}">{{translation['USER']}}</th>-->
        <th [ngClass]="{'hide-table-column': !showColumn('CLIENT')}">
          {{translation['CLIENT']}}</th>
        <th [ngClass]="{'hide-table-column': !showColumn('COMMENT')}">
          {{translation['COMMENT']}}</th>
        <th [ngClass]="{'hide-table-column': !showColumn('AMOUNT')}">{{translation['AMOUNT']}}
        </th>
        <th [ngClass]="{'hide-table-column': !showColumn('DATE')}">{{translation['DATE']}}</th>
        <th [ngClass]="{'hide-table-column': !showColumn('ACTIONS')}">{{translation['ACTIONS']}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of table.data" class="text-base">
        <!--<td [ngClass]="{'hide-table-column': !showColumn('USER')}">{{ data.user?.username }}</td>-->
        <td [ngClass]="{'hide-table-column': !showColumn('CLIENT')}">
          <a [routerLink]="getClientLink(data.client!._id!)"><nz-tag nzColor="purple">{{data.client?.prename}}
              {{data.client?.lastname}} ({{data.client?.clientNo}})
              &#64;{{data.client?.displayName}}</nz-tag></a>
        </td>
        <td [ngClass]="{'hide-table-column': !showColumn('COMMENT')}">
          {{data.comment}}
        </td>
        <td [ngClass]="{'hide-table-column': !showColumn('AMOUNT')}">
          <nz-tag nzColor="green" *ngIf="data.amount! > 0">{{totalAmount(data)}}</nz-tag>
          <nz-tag nzColor="red" *ngIf="data.amount! < 0">{{totalAmount(data)}}</nz-tag>
          <span *ngIf="data.amount! == 0">{{totalAmount(data)}}</span>
        </td>

        <td [ngClass]="{'hide-table-column': !showColumn('DATE')}">
          <nz-tag nzColor="green">
            <i-tabler name="calendar-stats" style="width:10px;height:10px;"></i-tabler>
            {{ getDateString(data.updatedAt) }}
          </nz-tag>
        </td>
        <td [ngClass]="{'hide-table-column': !showColumn('ACTIONS')}">
          <button nz-button nzType="primary" (click)="refund(data)"
            *ngIf="data.amount! > 0">{{translation['REFUND_DEPOSIT']}}</button>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>