<div class="w-full flex flex-col lg:flex-row lg:justify-center bg-violet-200">
  <div class="w-full lg:w-2/5 min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8 bg-white shadow-2xl p-8 rounded-md">
      <div>
        <h2 class="mt-6 text-center text-2xl font-semibold underline italic text-gray-900">Submit new password</h2>
      </div>
      <form class="mt-8 space-y-6" action="#" method="POST" [formGroup]="form" (ngSubmit)="submit()">
        <input type="hidden" name="remember" value="true">
        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <label for="username" class="sr-only">Email address</label>
            <input id="username" formControlName="email" type="text" autocomplete="email" required
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Email address">
          </div>
          <div>
            <label for="username" class="sr-only">Reset code</label>
            <input id="username" formControlName="resetCode" type="text" required
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Reset code">
          </div>
          <div>
            <label for="username" class="sr-only">Password</label>
            <input id="username" formControlName="password" type="password" required
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Password">
          </div>
          <div>
            <label for="password" class="sr-only">Confirm password</label>
            <input id="password" formControlName="confirm" type="password" autocomplete="current-password" required
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Confirm password">
          </div>
        </div>
        <div class="flex items-center justify-between">

          <div class="text-sm">
            <a href="/auth/login" class="font-medium text-indigo-600 hover:text-indigo-500 underline">Go to Login</a>
          </div>
        </div>
        <div>
          <button type="submit"
            class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <svg class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                  clip-rule="evenodd" />
              </svg>
            </span>
            { isSubmitting, select, true { Submitting... } false { Submit } }
          </button>
        </div>
      </form>
    </div>
  </div>
</div>