import { Component, OnDestroy, OnInit } from '@angular/core';
import { DepositDto } from 'src/app/core/dto/deposit.dto';
import { ITranslation } from 'src/app/core/interfaces/translation.interface';
import { CoreService } from 'src/app/core/services/core/core.service';
import { DepositService } from 'src/app/core/services/deposit/deposit.service';
import { StoreService } from 'src/app/core/services/store/store.service';
import { TranslationService } from 'src/app/core/services/translation/translation.service';

@Component({
  selector: 'app-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.css'],
})
export class DepositComponent implements OnInit, OnDestroy {
  deposits: DepositDto[] = [];
  selectedDeposit: DepositDto | undefined = undefined;
  pageSize: number = 10;
  translation: ITranslation = {};
  subscriptions: any[] = [];

  constructor(
    private depositService: DepositService,
    private translationService: TranslationService,
    private storeService: StoreService
  ) {
    this.subscriptions.push(
      this.translationService.translations.subscribe((translation) => {
        this.translation = translation;
      })
    );
    this.subscriptions.push(
      this.storeService.tablePageSize.subscribe((pageSize) => {
        this.pageSize = pageSize;
      })
    );
    this.subscriptions.push(
      this.storeService.deposits.subscribe((deposits) => {
        this.deposits = deposits;
      })
    );
  }

  ngOnDestroy(): void {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.getDeposit();
  }

  getDeposit() {
    this.depositService.getDeposits();
  }

  onItemClick(deposit: DepositDto): void {
    this.selectedDeposit = deposit;
  }

  onCloseModal(): void {
    this.selectedDeposit = undefined;
  }

  title(deposit: DepositDto): string {
    if (deposit.user) {
      const firstName = deposit.user.prename ? deposit.user.prename : '';
      const lastName = deposit.user.lastname ? deposit.user.lastname : '';
      return `${firstName} ${lastName}`;
    } else {
      return 'N/A';
    }
  }

  getClientLink(clientId: string): string {
    return `/${this.translationService.getLanguage()}/contacts/${clientId}`;
  }

  totalAmount(deposit: DepositDto): string {
    return deposit.amount!.toFixed(2);
  }

  getDateString(period: Date | string | undefined): string {
    return CoreService.getDateString(period, this.translationService);
  }

  getTableFields() {
    return this.allColumns;
  }
  allColumns: string[] = [/* 'USER', */ 'CLIENT', 'COMMENT', 'AMOUNT', 'DATE', 'ACTIONS'];
  selectedColumns: string[] = [...this.allColumns];
  selectColumns(event: string[]) {
    this.selectedColumns = event;
  }

  showColumn(column: string) {
    return this.selectedColumns.includes(column);
  }

  refund(deposit: DepositDto) {
    console.log('refund deposit', deposit);
    this.depositService.refundDeposit(deposit.clientId!, deposit.amount! * -1, '').subscribe((data) => {
      this.depositService.getDeposits();
    });
  }
}
