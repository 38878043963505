import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {

  form = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required]),
    resetCode: new UntypedFormControl('', [Validators.required]),
    password: new UntypedFormControl('', [Validators.required]),
    confirm: new UntypedFormControl('', [Validators.required])
  });

  isSubmitting : boolean = false;

  constructor(
    private message : NzMessageService,
    private authService: AuthService,
    private notification: NzNotificationService
  ) { }

  ngOnInit(): void {
  }

  submit() : void {
    if (this.form.value.password == this.form.value.confirm) {
      this.isSubmitting = true;
      this.authService.resetPasswordRequest(this.form.value.email, this.form.value.resetCode, this.form.value.password)
        .subscribe({
          next: () => {
          }
        })
    } else {
      this.message.warning('Your password and confirmation password do not match.');
    }
  }

}
