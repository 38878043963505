import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { HouseDto } from 'src/app/core/dto/house.dto';
import { RoomDto } from 'src/app/core/dto/room.dto';
import { ITranslation } from 'src/app/core/interfaces/translation.interface';
import { TranslationService } from 'src/app/core/services/translation/translation.service';

@Component({
  selector: 'house-item-box',
  templateUrl: './house-item.component.html',
  styleUrls: ['./house-item.component.css'],
})
export class HouseItemComponent implements OnInit, OnDestroy {
  @Input() house?: HouseDto;

  hiddenCoverPhoto: boolean = true;

  private subscriptions: any[] = [];
  translation: ITranslation = {};

  constructor(private translationService: TranslationService) {
    this.subscriptions.push(
      this.translationService.translations.subscribe((translation) => {
        this.translation = translation;
      })
    );
  }

  ngOnDestroy(): void {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  ngOnInit(): void {}

  get freeRooms(): RoomDto[] {
    let rooms: RoomDto[] = [];
    if (this.house?.rooms && this.house.rooms.length) {
      rooms = this.house.rooms.filter((e) => e.booking?.status?.toLocaleLowerCase() == 'free');
    }
    return rooms;
  }

  get bookedRooms(): RoomDto[] {
    let rooms: RoomDto[] = [];
    if (this.house?.rooms && this.house.rooms.length) {
      rooms = this.house.rooms.filter((e) => e.booking?.status?.toLocaleLowerCase() == 'booked');
    }
    return rooms;
  }

  get dailyPrices(): any[] {
    let prices: any[] = [];
    if (this.house?.prices?.daily) {
      prices.push({
        day: 'Mon',
        price: this.house.prices.daily.monday,
      });
      prices.push({
        day: 'Tue',
        price: this.house.prices.daily.tuesday,
      });
      prices.push({
        day: 'Wed',
        price: this.house.prices.daily.wednesday,
      });
      prices.push({
        day: 'Thu',
        price: this.house.prices.daily.thursday,
      });
      prices.push({
        day: 'Fri',
        price: this.house.prices.daily.friday,
      });
      prices.push({
        day: 'Sat',
        price: this.house.prices.daily.saturday,
      });
      prices.push({
        day: 'Sun',
        price: this.house.prices.daily.sunday,
      });
    }
    return prices;
  }

  hiddenCover(): void {
    this.hiddenCoverPhoto = !this.hiddenCoverPhoto;
  }
}
