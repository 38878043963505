<div class="flex flex-col py-10">
  <div class="flex flex-col md:flex-row md:justify-between items-start md:items-center">
    <table-column-filter [fields]="getTableFields()" (update)="selectColumns($event)"></table-column-filter>
  </div>
</div>
<div class="flex flex-col py-10">
  <nz-table #table [nzData]="cashouts" nzSize="small" [nzPageSizeOptions]="[5, 10, 25, 75, 100]"
    [nzShowSizeChanger]="true" nzPaginationPosition="top" [nzPageSize]="pageSize" class="table-scroll">
    <thead>
      <tr class="text-base">
        <th [ngClass]="{'hide-table-column': !showColumn('USER')}">{{translation['USER']}} {{translation['OR']}}
          {{translation['HOUSE']}}</th>
        <th [ngClass]="{'hide-table-column': !showColumn('NUMBER_OF_TRANSACTIONS')}">
          {{translation['NUMBER_OF_TRANSACTIONS']}}</th>
        <th [ngClass]="{'hide-table-column': !showColumn('SUM_OF_TRANSACTIONS')}">{{translation['SUM_OF_TRANSACTIONS']}}
        </th>
        <th [ngClass]="{'hide-table-column': !showColumn('DATE')}">{{translation['DATE']}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of table.data" class="text-base">
        <td [ngClass]="{'hide-table-column': !showColumn('USER')}">{{ title(data) }}</td>
        <td [ngClass]="{'hide-table-column': !showColumn('NUMBER_OF_TRANSACTIONS')}">
          <nz-tag nzColor="purple">{{numberOfTransactionIds(data)}}</nz-tag>
        </td>
        <td [ngClass]="{'hide-table-column': !showColumn('SUM_OF_TRANSACTIONS')}">
          <nz-tag nzColor="green">€{{totalAmount(data)}}</nz-tag>
        </td>
        <td [ngClass]="{'hide-table-column': !showColumn('DATE')}">
          <nz-tag nzColor="green">
            <i-tabler name="calendar-stats" style="width:10px;height:10px;"></i-tabler>
            {{ getDateString(data.updatedAt) }}
          </nz-tag>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>