import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LanguageGuard } from './core/guards/language.guard';

const routes: Routes = [
  {
    path: 'auth',
    redirectTo: '/de/auth',
  },
  {
    path: ':lang/auth',
    loadChildren: () => import('./features/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [],
  },
  {
    path: ':lang',
    loadChildren: () => import('./features/home/home.module').then((m) => m.HomeModule),
    canActivate: [LanguageGuard, AuthGuard],
  },
  {
    path: ':lang/booking',
    loadChildren: () => import('./features/booking/booking.module').then((m) => m.BookingModule),
    canActivate: [LanguageGuard, AuthGuard],
  },

  /*{
    path: '',
    loadChildren: () => import('./features/home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard],
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./features/auth/auth.module').then((m) => m.AuthModule),
        canActivate: [],
      },
      {
        path: '',
        loadChildren: () => import('./features/home/home.module').then((m) => m.HomeModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'booking',
        loadChildren: () => import('./features/booking/booking.module').then((m) => m.BookingModule),
        canActivate: [AuthGuard],
      },
    ],
  },*/
  /*{
    path: '**',
    redirectTo: '/de/auth/login',
  },*/
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
