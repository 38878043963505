import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StoreService } from '../services/store/store.service';
import { TokenStorageService } from '../services/token/token-storage.service';
import { TranslationService } from '../services/translation/translation.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(
    private tokenService: TokenStorageService,
    private router: Router,
    private translationService: TranslationService,
    private storeService: StoreService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.tokenService.isLoggedIn !== true) {
      this.storeService.login.next(false);
      this.router.navigate([this.translationService.getLanguage(), 'auth', 'login']);
    }
    return true;
  }
}
