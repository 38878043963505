import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TranslationService } from '../services/translation/translation.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private message: NzMessageService,
    private translationService: TranslationService
  ) {}

  private lastError: {
    when: Date | null;
    what: number | null;
  } = {
    when: null,
    what: null,
  };

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event) => {
        if (event instanceof HttpResponse) {
          if (!event.body.success && event.body.message) {
            this.message.error(event.body.message);
          } else {
            event = event.clone({ body: event.body.payload });
          }
        }
        return event;
      }),
      catchError((error) => this.handleError(error, req, next))
    );
  }

  private handleError(err: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    let errorMsg = '';
    let show = true;
    if (this.lastError.when !== null) {
      if (this.lastError.when.getTime() > new Date().getTime() - 2000) {
        if (this.lastError.what !== null && this.lastError.what === err.status) {
          show = false;
        }
      }
    }
    this.lastError.when = new Date();
    this.lastError.what = err.status;

    if (err.error instanceof ErrorEvent) {
      errorMsg = `Error: ${err.error.message}`;
      if (show) {
        this.message.error(errorMsg);
      }
    } else {
      if (err.status === 403) {
        this.router.navigate([this.translationService.getLanguage(), 'auth', 'login']);
      }
      errorMsg = `Error Code: ${err.status}, Message: ${err.message}`;
      if (show) {
        this.message.error(errorMsg);
      }
    }
    return throwError(() => new Error(errorMsg));
  }
}

export const errorInterceptorPorviders = [{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }];
