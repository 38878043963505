import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenStorageService } from '../services/token/token-storage.service';
import { TranslationService } from '../services/translation/translation.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageGuard  {
  constructor(
    private tokenService: TokenStorageService,
    private router: Router,
    private translationService: TranslationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if ('lang' in route.params) {
      if (['de', 'en'].includes(route.params['lang'])) {
        this.translationService.setLanguage(route.params['lang']);
        return true;
      } else {
        this.router.navigate([this.translationService.getLanguage(), state.url]);
        // return true;
      }
    } else {
      this.router.navigate([this.translationService.getLanguage(), state.url]);
      // return true;
    }
    return true;
  }
}
