import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ITranslationWrapper } from '../../interfaces/translation-wrapper.interface';
import { ITranslation } from '../../interfaces/translation.interface';
import { TranslationDE } from './translation.de';
import { TranslationEN } from './translation.en';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  public translations = new BehaviorSubject<ITranslation>({});

  private languages: ITranslationWrapper = {
    de: new TranslationDE(),
    en: new TranslationEN(),
  };

  private days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  // public state = new BehaviorSubject<IState>({});
  private language: string = 'de';
  constructor() {
    this.setLanguage(this.language);
  }

  getLanguage(): string {
    return this.language;
  }

  setLanguage(language: string) {
    if (language in this.languages) {
      this.language = language;
      this.translations.next(this.languages[this.language].translation);
    }
  }

  getWeekDay(day: number): string {
    return this.languages[this.language].translation[this.days[day]];
  }

  getCountry(name: string): string {
    const nameChk = name.toLocaleUpperCase().replace(new RegExp(/\s+/g), '_');
    if (this.languages[this.language].countries && this.languages[this.language].countries[nameChk]) {
      return this.languages[this.language].countries[nameChk];
    }
    // if (name !== 'NA') console.log(name);
    return name;
  }
  /*
  setStateForNumber(key: string, value: number) {
    let current = this.state.getValue();
    // @ts-ignore
    current[key] = value;
    this.state.next(current);
  }

  setStateForString(key: string, value: string) {
    let current = this.state.getValue();
    // @ts-ignore
    current[key] = value;
    this.state.next(current);
  }
  */
}
