import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { ISocketUpdate } from '../../interfaces/socket-update.interface';
import { StoreService } from '../store/store.service';
import { TokenStorageService } from '../token/token-storage.service';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  public messages$: BehaviorSubject<ISocketUpdate> = new BehaviorSubject({ entity: '', payload: null });

  private socket?: Socket;

  constructor(private tokenService: TokenStorageService, private storeService: StoreService) {
    this.storeService.login.subscribe((login) => {
      if (login) {
        this.socket = io(`${environment.wssProtocol}://${environment.wssUrl}/updates`, {
          autoConnect: true,
          extraHeaders: {
            Authorization: this.tokenService.getToken() ?? '',
          },
        });
      } else {
        try {
          this.socket?.close();
        } catch (error) {}
      }
    });
  }

  public getMessage = (entity: string) => {
    this.socket?.on(entity, (message) => {
      this.messages$.next({ entity: entity, payload: message });
    });
    return this.messages$.asObservable();
  };
}
