import {
  animate,
  style,
  state,
  transition,
  trigger
} from "@angular/animations";

export const MenuAnimation = trigger("toggleMenu", [
  state('open', style({
    display: 'block',
    opacity: 1,
  })),
  state('closed', style({
    display: 'none',
    opacity: 0
  })),
  transition('open => closed', [
    animate('.3s')
  ]),
  transition('closed => open', [
    animate('.3s')
  ])
]);