import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ClientDto } from '../../dto/client.dto';
import { DepositDto } from '../../dto/deposit.dto';
import { HouseDto } from '../../dto/house.dto';
import { MessageDto } from '../../dto/message.dto';
import { RoomDto } from '../../dto/room.dto';
import { IYear } from '../../interfaces/weeks.interface';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  public houses = new BehaviorSubject<HouseDto[]>([]);
  public clients = new BehaviorSubject<ClientDto[]>([]);
  public deposits = new BehaviorSubject<DepositDto[]>([]);

  public rooms = new BehaviorSubject<RoomDto[]>([]);
  public allRooms = new BehaviorSubject<RoomDto[]>([]);

  public appVisible = new BehaviorSubject<boolean>(true);
  public login = new BehaviorSubject<boolean>(true);

  public fetchAnalysis = new BehaviorSubject<boolean>(false);

  public weeks = new BehaviorSubject<IYear>({});
  public selectedYear = new BehaviorSubject<number>(new Date().getFullYear());

  public tablePageSize = new BehaviorSubject<number>(25);
  public selectedWeekIndex = new BehaviorSubject<number>(-1);
  public selectedYearIndex = new BehaviorSubject<number>(0);
  public selectedRoomStatusIndex = new BehaviorSubject<number>(0);
  public selectedTypeIndex = new BehaviorSubject<number>(2);
  public dateFilter = new BehaviorSubject<Date | null>(null);
  public yearFilter = new BehaviorSubject<Date | null>(new Date());
  public availableYearList = new BehaviorSubject<number[]>([]);
  public messages = new BehaviorSubject<MessageDto[]>([]);

  public sidebarCollapsed = new BehaviorSubject<boolean>(false);

  public history = new BehaviorSubject<string[]>(['/de']);
  constructor() {}

  hasYear(year: number): boolean {
    // @ts-ignore
    if (Object.keys(this.weeks.value).includes(year.toString())) {
      return true;
    }

    return false;
  }

  hasWeekData(year: number, week: number): boolean {
    // @ts-ignore
    if (Object.keys(this.weeks.value).includes(year.toString())) {
      let yearData = this.weeks.value[year];
      if (yearData[week][week].isLoaded) {
        return true;
      }
      return false;
    }
    return false;
  }

  setFilterDate(newFilterDate: Date | null) {
    this.dateFilter.next(newFilterDate);
  }

  setFilterYear(newFilterYear: Date | null) {
    this.yearFilter.next(newFilterYear);
  }
}
