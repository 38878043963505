import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { UserDto } from 'src/app/core/dto/user.dto';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  constructor(private http: HttpClient) { }

  login(username: string, password: string) : Observable<UserDto> {
    return this.http.post(environment.apiUrl + 'authentication', {
      username,
      password
    }, httpOptions)
    .pipe(
      map((data: any) => data)
    );
  }

  register(username: string, password: string) : Observable<UserDto> {
    return this.http.post(environment.apiUrl + 'users', {
      username,
      password
    }, httpOptions)
    .pipe(
      map((data: any) => data)
    );
  }

  passwordResetRequest(email: string) : Observable<any> {
    return this.http.post(environment.apiUrl + 'users/passwordResetRequest', {
      email: email
    }, httpOptions)
    .pipe(
      map((data: any) => data)
    )
  }

  resetPasswordRequest(email: string, resetCode: string, password: string) : Observable<any> {
    return this.http.post(environment.apiUrl + 'users/passwordChange', {
      email,
      resetCode,
      password
    }, httpOptions)
    .pipe(
      map((data: any) => data)
    )
  }
}
