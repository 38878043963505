import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { HouseDto } from 'src/app/core/dto/house.dto';
import { RoomDto } from 'src/app/core/dto/room.dto';
import { ITranslation } from 'src/app/core/interfaces/translation.interface';
import { TranslationService } from 'src/app/core/services/translation/translation.service';

@Component({
  selector: 'house-prices-box',
  templateUrl: './house-prices.component.html',
  styleUrls: ['./house-prices.component.css'],
})
export class HousePricesComponent implements OnInit, OnDestroy {
  @Input() house?: HouseDto;
  @Input() room?: RoomDto;

  private subscriptions: any[] = [];
  translation: ITranslation = {};
  constructor(private translationService: TranslationService) {
    this.subscriptions.push(
      this.translationService.translations.subscribe((translation) => {
        this.translation = translation;
      })
    );
  }

  ngOnDestroy(): void {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  ngOnInit(): void {}
}
