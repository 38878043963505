import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DepositDto } from '../../dto/deposit.dto';
import { ISocketUpdate } from '../../interfaces/socket-update.interface';
import { SocketService } from '../socket/socket.service';
import { StoreService } from '../store/store.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class DepositService {
  private initalLoad = false;
  private isVisble = false;
  private isFetching = false;

  constructor(private http: HttpClient, private storeService: StoreService, private socketService: SocketService) {
    this.socketService.getMessage('deposit').subscribe((message: ISocketUpdate) => {
      if (message.entity === 'deposit') {
        if (this.initalLoad && this.isVisble) {
          this.getDeposits();
        }
      }
    });

    this.storeService.appVisible.subscribe((isVisble) => {
      this.isVisble = isVisble;
      if (isVisble && this.initalLoad) {
        this.getDeposits();
      }
    });
    this.storeService.login.subscribe((login) => {
      if (login) {
        setTimeout(() => {
          this.isFetching = false;
          this.getDeposits();
          this.initalLoad = true;
        }, 200);
      } else {
        this.initalLoad = false;
      }
    });
  }

  getDeposits(): Observable<DepositDto[]> {
    // console.log('getClients is called');
    if (!this.isFetching) {
      this.isFetching = true;
      let result = this.http.get(environment.apiUrl + 'bail', httpOptions).pipe(map((data: any) => data));
      result.subscribe((data: DepositDto[]) => {
        // console.log(data);
        data = data.sort((a, b) => {
          if (new Date(a.amount!) < new Date(b.amount!)) {
            return 1;
          }
          return -1;
        });
        this.storeService.deposits.next(data);
        this.isFetching = false;
      });
      return result;
    }
    return from([]);
  }

  refundDeposit(clientId: string, amount: number, comment: string): Observable<DepositDto> {
    if (amount > 0) {
      amount = -1 * amount;
    }
    console.log(
      'refund',
      {
        clientId: clientId,
        amount: amount,
        comment: comment,
      },
      typeof amount
    );
    return this.http
      .post(
        environment.apiUrl + 'bail/refund',
        {
          clientId: clientId,
          amount: amount,
          comment: comment,
        },
        httpOptions
      )
      .pipe(map((data: any) => data));
  }

  payDeposit(clientId: string, amount: number, comment: string): Observable<DepositDto> {
    return this.http
      .post(
        environment.apiUrl + 'bail/pay',
        {
          clientId: clientId,
          amount: amount,
          comment: comment,
        },
        httpOptions
      )
      .pipe(map((data: any) => data));
  }
}
