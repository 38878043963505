<div class="flex items-stretch md:items-center justify-center min-h-full text-center md:px-2 lg:px-4">
  <div class="flex text-base text-left w-full max-w-full md:max-w-2xl md:px-4 md:my-8 lg:max-w-4xl">
    <nz-ribbon [nzText]="houseInfo!" class="w-full">
      <nz-card>
        <div nz-card-grid [ngStyle]="{width: '100%'}">
          <div
            class="w-full relative flex items-center bg-white px-4 pt-14 pb-8 overflow-hidden sm:px-6 sm:pt-8 md:p-6 lg:p-8">
            <div class="w-full items-start">
              <div *ngIf="!hiddenCoverPhoto && house?.thumb && house?.thumb?.length"
                class="rounded-lg bg-gray-100 overflow-hidden h-60 mb-4">
                <img [src]="house?.thumb" [alt]="house?.name" class="object-center object-cover h-60 w-full bg-cover">
              </div>
              <div>
                <div class="w-full flex justify-between">
                  <h2 class="text-xl underline font-bold text-gray-900 sm:pr-12">{{ house?.name }}</h2>
                  <button class="text-sm italic text-slate-600" (click)="hiddenCover()"
                    *ngIf="house?.thumb && house?.thumb?.length">
                    {{ hiddenCoverPhoto ? translation['SHOW_COVER'] : translation['HIDE_COVER'] }}
                  </button>
                </div>
                <section aria-labelledby="information-heading" class="mt-2">
                  <span class="py-2 text-sm text-slate-500"
                    *ngIf="house?.description && house?.description?.length">{{house?.description}}</span>
                  <div class="flex flex-col items-start pt-2">
                    <p class="text-md text-gray-900 pb-2"><nz-tag>{{translation['WEEKLY']}}:
                        ${{house?.prices?.weekly}}</nz-tag></p>
                    <div class="flex w-full flex-wrap">
                      <div *ngFor="let item of dailyPrices" class="sm:pr-2 text-xs sm:text-sm pb-1 sm:pb-0">
                        <nz-tag [nzColor]="item['day'] == 'Sat' || item['day'] == 'Sun' ? 'red' : 'default'">
                          {{ item['day'] }} : €{{ item['price'] }}
                        </nz-tag>
                      </div>
                    </div>
                  </div>
                </section>
                <section aria-labelledby="options-heading">
                  <form>
                    <div class="mt-10" *ngIf="freeRooms.length">
                      <div class="flex items-center justify-between">
                        <h4 class="text-sm text-gray-900 font-medium">{{translation['FREE_ROOMS']}}</h4>
                      </div>
                      <fieldset class="mt-4">
                        <div class="grid grid-cols-4 gap-4" *ngIf="token.getUserReadOnly()">
                          <button *ngFor="let room of freeRooms" nz-button nzSize="large" nzType="dashed"
                            class="group relative">
                            {{ room.roomNumber }}
                          </button>
                        </div>
                        <div class="grid grid-cols-4 gap-4" *ngIf="!token.getUserReadOnly()">
                          <button *ngFor="let room of freeRooms" nz-button nzSize="large" nzType="dashed" dndDropzone
                            dndDragoverClass="droppable" (dndDrop)="onDrop($event, room)"
                            (click)="onClickFreeRoom($event, room, tplNewBooking)" class="group relative">
                            {{ room.roomNumber }}
                          </button>
                        </div>
                      </fieldset>
                    </div>
                    <div class="mt-10" *ngIf="bookedRooms.length">
                      <div class="flex items-center justify-between">
                        <h4 class="text-sm text-gray-900 font-medium">{{translation['BOOKED']}}</h4>
                      </div>
                      <fieldset class="mt-4">
                        <div class="grid grid-cols-4 gap-4" *ngIf="!token.getUserReadOnly()">
                          <button *ngFor="let room of bookedRooms" nz-button nzType="default" nzSize="large"
                            [dndDraggable]="room" dndType="booked" dndEffectAllowed="all" dndDraggingClass="draggable"
                            dndDraggingSourceClass="draggableSource" class="relative" nz-popover
                            [nzPopoverTitle]="bookedTitle" [nzPopoverContent]="bookedContent" nzPopoverPlacement="top"
                            nzPopoverTrigger="click" (click)="onClickBookedRoom($event, room)">
                            <div class=" justify-center items-center text-xs overflow-ellipsis">
                              <div style="display: inline-block;">
                                <h2>{{room.name}} ({{room.roomNumber}})</h2>
                              </div>
                              <div style="display: flex;" class="flex justify-center items-center">
                                <img
                                  [src]="room.booking?.client?.thumb && room.booking?.client?.thumb?.length ? room.booking?.client?.thumb : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxwa1y6Q-tWrqCjBfDQQxWUeXxXROgDlLJ22wbJcVHCA-LX3dDKCxjfojzK-wQOpctwi0&usqp=CAU'"
                                  [alt]="room.booking?.client?.displayName" class="w-5 h-5 rounded-full mr-2">
                                <span> {{ room.booking?.client?.prename }} {{room.booking?.client?.lastname}}
                                </span>
                              </div>
                            </div>
                          </button>
                        </div>
                        <div class="grid grid-cols-4 gap-4" *ngIf="token.getUserReadOnly()">
                          <button *ngFor="let room of bookedRooms" nz-button nzType="default" nzSize="large">
                            <div class=" justify-center items-center text-xs overflow-ellipsis">
                              <div style="display: inline-block;">
                                <h2>{{room.name}} ({{room.roomNumber}})</h2>
                              </div>
                              <div style="display: flex;" class="flex justify-center items-center">
                                <img
                                  [src]="room.booking?.client?.thumb && room.booking?.client?.thumb?.length ? room.booking?.client?.thumb : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxwa1y6Q-tWrqCjBfDQQxWUeXxXROgDlLJ22wbJcVHCA-LX3dDKCxjfojzK-wQOpctwi0&usqp=CAU'"
                                  [alt]="room.booking?.client?.displayName" class="w-5 h-5 rounded-full mr-2">
                                <span> {{ room.booking?.client?.prename }} {{room.booking?.client?.lastname}}
                                </span>
                              </div>
                            </div>
                          </button>
                        </div>
                      </fieldset>
                    </div>
                    <div class="mt-10" *ngIf="notAllocatedClients.length">
                      <div class="flex items-center justify-between">
                        <h4 class="text-sm text-gray-900 font-medium">{{translation['NOT_ASSIGNED_CLIENTS']}}</h4>
                      </div>
                      <fieldset class="mt-4">
                        <div class="grid grid-cols-3 gap-4" *ngIf="token.getUserReadOnly()">
                          <button *ngFor="let client of notAllocatedClients" nz-button nzType="default" nzBlock
                            nzSize="large">
                            <div class="flex justify-center items-center text-xs">
                              <img
                                [src]="client.thumb && client.thumb.length ? client.thumb : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxwa1y6Q-tWrqCjBfDQQxWUeXxXROgDlLJ22wbJcVHCA-LX3dDKCxjfojzK-wQOpctwi0&usqp=CAU'"
                                [alt]="client.displayName" class="w-5 h-5 rounded-full mr-2">
                              <span>{{ client.displayName }}({{client.clientNo}}) {{client.prename }}
                                {{client.lastname}}</span>
                            </div>
                          </button>
                        </div>
                        <div class="grid grid-cols-3 gap-4" *ngIf="!token.getUserReadOnly()">
                          <button *ngFor="let client of notAllocatedClients" nz-button nzType="default" nzBlock
                            nzSize="large" [dndDraggable]="client" dndType="not_assigned_client" dndEffectAllowed="all"
                            dndDraggingClass="draggable" dndDraggingSourceClass="draggableSource" class="relative">
                            <div class="flex justify-center items-center text-xs">
                              <img
                                [src]="client.thumb && client.thumb.length ? client.thumb : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxwa1y6Q-tWrqCjBfDQQxWUeXxXROgDlLJ22wbJcVHCA-LX3dDKCxjfojzK-wQOpctwi0&usqp=CAU'"
                                [alt]="client.displayName" class="w-5 h-5 rounded-full mr-2">
                              <span>{{ client.displayName }}({{client.clientNo}}) {{client.prename }}
                                {{client.lastname}}</span>
                            </div>
                          </button>
                        </div>
                      </fieldset>
                    </div>
                  </form>
                </section>
              </div>
            </div>
          </div>
        </div>
      </nz-card>
    </nz-ribbon>
  </div>
  <ng-template #tplTitle>
    <div class="flex items-center">
      <img
        [src]="selectedBookedRoom!.booking?.client?.thumb && selectedBookedRoom!.booking?.client?.thumb?.length ? selectedBookedRoom!.booking?.client?.thumb : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxwa1y6Q-tWrqCjBfDQQxWUeXxXROgDlLJ22wbJcVHCA-LX3dDKCxjfojzK-wQOpctwi0&usqp=CAU'"
        [alt]="selectedBookedRoom!.booking?.client?.displayName" class="w-5 h-5 rounded-full mr-2">
      <span> {{ selectedBookedRoom!.booking?.client?.prename }} {{selectedBookedRoom!.booking?.client?.lastname}}</span>
      <span>({{selectedBookedRoom!.roomNumber}})</span>
    </div>
  </ng-template>
  <ng-template #tplNewBooking let-params>
    <form nz-form action="#" method="POST" [formGroup]="params.form" (ngSubmit)="params.submit()">
      <house-prices-box [house]="params.house" [room]="params.room"></house-prices-box>
      <nz-descriptions [nzTitle]="translation['BOOKING']">
        <nz-descriptions-item [nzTitle]="translation['BOOKING_PERIOD']" [nzSpan]="3">
          <nz-range-picker nzShowTime=true nzFormat="dd.MM.yyyy" formControlName="period"></nz-range-picker>
        </nz-descriptions-item>
      </nz-descriptions>
      <nz-form-item>
        <nz-form-label>{{translation['IS_ROOM_FIXED']}}</nz-form-label>
        <nz-form-control nzSpan="4" class="pt-0.5">
          <input nz-input type="checkbox" formControlName="fixedRoom" class="w-fit">
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control [nzXs]="{span: 14}" [nzSm]="{span: 14, offset: 5}">
          <nz-select nzShowSearch nzAllowClear [nzPlaceHolder]="translation['SELECT_CLIENT']" formControlName="client"
            [nzFilterOption]="params.filterClients">
            <nz-option *ngFor="let c of clients" [nzValue]="c._id"
              [nzLabel]="c.displayName + ' (' + c.prename + ' ' + c.lastname + ') - ' + c.clientNo"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control [nzXs]="{span: 14}" [nzSm]="{span: 14, offset: 5}">
          <nz-input-group nzSuffix="€">
            <input nz-input formControlName="price" [placeholder]="translation['PRICE']">
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control [nzXs]="{span: 14}" [nzSm]="{span: 14, offset: 5}">
          <textarea [placeholder]="translation['NOTES']" formControlName="notes" nz-input
            [nzAutosize]="{minRows: 2, maxRows: 6}"></textarea>
        </nz-form-control>
      </nz-form-item>
      <button [nzLoading]="isSubmitting" nz-button nzType="primary" nzBlock>{{translation['SUBMIT']}}</button>
    </form>
  </ng-template>
  <ng-template #bookedTitle>
    <div class="flex items-center">
      <img
        [src]="selectedBookedRoom!.booking?.client?.thumb && selectedBookedRoom!.booking?.client?.thumb?.length ? selectedBookedRoom!.booking?.client?.thumb : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxwa1y6Q-tWrqCjBfDQQxWUeXxXROgDlLJ22wbJcVHCA-LX3dDKCxjfojzK-wQOpctwi0&usqp=CAU'"
        [alt]="selectedBookedRoom!.booking?.client?.displayName" class="w-5 h-5 rounded-full mr-2">
      <span> {{ selectedBookedRoom!.booking?.client?.prename }} {{selectedBookedRoom!.booking?.client?.lastname}}</span>
      <span>({{selectedBookedRoom!.roomNumber}})</span>
    </div>
  </ng-template>
  <ng-template #bookedContent>
    <div class="flex flex-col">
      <button nz-button class="mb-2" nzDanger nzType="default" nz-popconfirm
        [nzPopconfirmTitle]="translation['CONFIRM_DELETE_BOOKING']" nzPopconfirmPlacement="bottom"
        (nzOnConfirm)="cancelBooking()">
        {{translation['CANCEL_BOOKING_BY']}} {{ selectedBookedRoom!.booking?.client?.prename }}
        {{selectedBookedRoom!.booking?.client?.lastname}}
      </button>
      <button nz-button class="mb-2" (click)="editBooking(tplEditBooking)">{{translation['EDIT_BOOKING']}}</button>
      <button nz-button class="mb-2" (click)="createPayment(tplPayment)">{{translation['CREATE_PAYMENT']}}</button>
      <button nz-button class="mb-2"
        (click)="removeRoomFromBooking()">{{translation['REMOVE_ROOM_FROM_BOOKING']}}</button>
      <button nz-button (click)="viewClient()">{{translation['VIEW_CLIENT_DETAILS']}}</button>
    </div>
  </ng-template>
  <ng-template #tplEditBooking let-params>
    <form nz-form action="#" method="POST" [formGroup]="params.form" (ngSubmit)="params.submit()">
      <house-prices-box [house]="params.house" [room]="params.room"></house-prices-box>
      <nz-descriptions nzTitle="Booking"></nz-descriptions>
      <nz-form-item>
        <nz-form-label>{{translation['IS_ROOM_FIXED']}}</nz-form-label>
        <nz-form-control nzSpan="4" class="pt-0.5 flex items-center">
          <input nz-input type="checkbox" formControlName="fixedRoom" class="w-fit">
        </nz-form-control>
      </nz-form-item>
      <nz-form-item *ngIf="params.isStarted">
        <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired>{{translation['PERIOD_END']}}</nz-form-label>
        <nz-form-control [nzSm]="16" [nzXs]="24">
          <span>{{params.room.booking.period.start | date:'dd.MM.yyyy' }} ~ </span>
          <nz-date-picker nzFormat="dd.MM.yyyy" formControlName="periodEnd"></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item *ngIf="!params.isStarted">
        <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired>{{translation['BOOKING_PERIOD']}}</nz-form-label>
        <nz-form-control [nzSm]="16" [nzXs]="24">
          <nz-range-picker nzFormat="dd.MM.yyyy" nzShowTime=true formControlName="period"></nz-range-picker>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired>{{translation['CLIENT']}}</nz-form-label>
        <nz-form-control [nzSm]="16" [nzXs]="24">
          <nz-select nzShowSearch nzAllowClear [nzPlaceHolder]="translation['SELECT_CLIENT']" formControlName="client"
            [nzFilterOption]="params.filterClients">
            <nz-option *ngFor="let c of clients" [nzValue]="c._id"
              [nzLabel]="c.displayName + ' (' + c.prename + ' ' + c.lastname + ') - ' + c.clientNo"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired>{{translation['TOTAL_PRICE']}}</nz-form-label>
        <nz-form-control [nzSm]="16" [nzXs]="24">
          <nz-input-group *ngIf="params.oldPrice" [nzAddOnBefore]="addOnBeforeTemplate" nzSuffix="€">
            <ng-template #addOnBeforeTemplate>
              {{ params.oldPrice }}
            </ng-template>
            <input nz-input formControlName="total" name="required" required />
          </nz-input-group>
          <input *ngIf="!params.oldPrice" nz-input formControlName="total" name="required" required />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="8" [nzXs]="24">{{translation['NOTES']}}</nz-form-label>
        <nz-form-control [nzSm]="16" [nzXs]="24">
          <textarea placeholder="Notes" formControlName="notes" nz-input
            [nzAutosize]="{minRows: 2, maxRows: 6}"></textarea>
        </nz-form-control>
      </nz-form-item>
      <button [nzLoading]="isSubmitting" nz-button nzType="primary" nzBlock>{{translation['SUBMIT']}}</button>
    </form>
  </ng-template>
  <ng-template #tplPayment let-params>
    <form nz-form action="#" method="POST" [formGroup]="params.form" (ngSubmit)="params.submit()">
      <house-prices-box [house]="params.house" [room]="params.room"></house-prices-box>
      <nz-descriptions [nzTitle]="translation['PAYMENT']"></nz-descriptions>
      <nz-form-item>
        <nz-form-label nzRequired>{{translation['PAYMENT_DATE']}}</nz-form-label>
        <nz-form-control nzSm="24">
          <nz-date-picker formControlName="paymentDate"></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzRequired>{{translation['SUBMIT']}}</nz-form-label>
        <nz-form-control nzSm="24">
          <nz-input-group nzSuffix="€">
            <input nz-input formControlName="amount" name="required" required />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <button [nzLoading]="isSubmitting" nz-button nzType="primary" nzBlock>{{translation['SUBMIT']}}</button>
    </form>
  </ng-template>
</div>