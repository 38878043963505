import { Component, HostListener } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, map } from 'rxjs';
import { StoreService } from './core/services/store/store.service';
import { TokenStorageService } from './core/services/token/token-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'App Intim Trier';

  constructor(
    private storeService: StoreService,
    private tokenService: TokenStorageService,
    private swUpdate: SwUpdate
  ) {}

  appHidden = false;
  @HostListener('document:visibilitychange', ['$event'])
  appVisibility() {
    if (document.hidden) {
      //do whatever you want
      this.appHidden = true;
      this.storeService.appVisible.next(false);
    } else {
      //do whatever you want
      this.appHidden = false;
      this.storeService.appVisible.next(true);
    }
  }

  ngOnInit(): void {
    this.docReady(() => {
      window.addEventListener(
        'resize',
        () => {
          const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
          localStorage.setItem('iwa_admin_dashboard_zoom_value', browserZoomLevel.toFixed(2));
        },
        false
      );
    });
    /*
    (function ($) {
      $(document).ready(function () {
        window.addEventListener(
          'resize',
          function () {
            const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
            localStorage.setItem('iwa_admin_dashboard_zoom_value', browserZoomLevel.toFixed(2));
          },
          false
        );
      });
    })(jQuery);
    */
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates
        .pipe(
          filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
          map((evt) => ({
            type: 'UPDATE_AVAILABLE',
            current: evt.currentVersion,
            available: evt.latestVersion,
          }))
        )
        .subscribe((evt) => {
          if (evt.type == 'UPDATE_AVAILABLE') {
            if (confirm('Neue Verion verfügbar. Soll diese installiert werden?')) {
              window.location.reload();
            }
          }
        });
    }
  }

  private docReady(fn: any) {
    // see if DOM is already available
    if (document.readyState === 'complete' || document.readyState === 'interactive') {
      // call on next available tick
      setTimeout(fn, 1);
    } else {
      document.addEventListener('DOMContentLoaded', fn);
    }
  }

  onActivate(event: any) {
    console.log('---- APP on activate -----');
    console.log(event);
    try {
      event.blubs();
    } catch (error) {}

    console.log(event.constructor.name);
    console.log('---- APP on activate END -----');
  }
}
