import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { PasswordResetComponent } from "./password-reset/password-reset.component";
import { PasswordComponent } from "./password/password.component";
import { RegisterComponent } from "./register/register.component";

const routes: Routes = [
	{ path: 'login', component: LoginComponent },
	{ path: 'sign-up', component: RegisterComponent },
	{ path: 'forgot-password', component: PasswordComponent },
  { path: 'reset-password', component: PasswordResetComponent }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})

export class AuthRoutingModule {}