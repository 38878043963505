import { Component, OnDestroy, OnInit } from '@angular/core';
import { ClientDto } from 'src/app/core/dto/client.dto';
import { HouseDto } from 'src/app/core/dto/house.dto';
import { ITranslation } from 'src/app/core/interfaces/translation.interface';
import { BookingService } from 'src/app/core/services/booking/booking.service';
import { StoreService } from 'src/app/core/services/store/store.service';
import { TranslationService } from 'src/app/core/services/translation/translation.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css'],
})
export class OverviewComponent implements OnInit, OnDestroy {
  houses: HouseDto[] = [];
  selectedHouse?: HouseDto | null;
  clients: ClientDto[] = [];
  selectedClients: ClientDto[] = [];
  isSubmitting: boolean = false;

  translation: ITranslation = {};
  private subscriptions: any[] = [];

  constructor(
    private bookingService: BookingService,
    // private contactService: ContactService,
    private translationService: TranslationService,
    private storeService: StoreService
  ) {
    this.subscriptions.push(
      this.translationService.translations.subscribe((translation) => {
        this.translation = translation;
      })
    );
    this.subscriptions.push(
      this.storeService.clients.subscribe((clients) => {
        this.clients = clients;
      })
    );
  }

  ngOnDestroy(): void {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
  ngOnInit(): void {
    this.fetchBookings();
    // this.fetchClients();
  }

  /*
  fetchClients(): void {
    this.contactService.getClients().subscribe({
      next: (data) => {
        this.clients = data;
      },
    });
  }
  */
  fetchBookings(): void {
    this.bookingService.getBookings().subscribe({
      next: (data) => {
        this.houses = data;
      },
    });
  }

  onItemClick(house: HouseDto): void {
    this.selectedHouse = house;
  }

  onCloseModal(): void {
    this.selectedHouse = null;
  }

  update(): void {
    this.fetchBookings();
  }
}
