<div class="group relative cursor-pointer" (click)="onClick($event)">
	<div class="w-full shadow p-4 rounded-md flex flex-col">
		<div class="w-full flex justify-between">
			{{ house?.name }}
			<div *ngIf="house?.active" class="bg-green-200 text-green-900 px-2 py-1 rounded-full text-xs">
				{{translation['ACTIVE']}}
			</div>
		</div>
		<div class="w-full" *ngIf="house?.description">
			{{house?.description}}
		</div>
		<div class="w-full grid grid-cols-2 divide-x py-3">
			<div class="text-center">
				<div class="text-blue-500 font-medium text-lg">{{house?.freeRooms}}</div>
				<div class="font-light text-xs">{{translation['FREE_ROOMS']}}</div>
			</div>
			<div class="text-center">
				<div class="text-blue-500 font-medium text-lg">{{house?.bookedRooms}}</div>
				<div class="font-light text-xs">{{translation['BOOKED_ROOMS']}}</div>
			</div>
		</div>
	</div>
</div>