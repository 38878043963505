<div class="w-full flex flex-col lg:flex-row lg:justify-center sm:bg-violet-200 bg-white">
  <div class="w-full lg:w-2/5 min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8 bg-white shadow-none sm:shadow-2xl p-8 rounded-md">
      <div>
        <h2 class="mt-6 text-center text-2xl font-semibold underline italic text-gray-900">
          {{translation['SIGN_IN_ACCOUNT']}}</h2>
      </div>
      <form class="mt-8 space-y-6" action="#" method="POST" [formGroup]="form" (ngSubmit)="submit()">
        <input type="hidden" name="remember" value="true">
        <div class="rounded-md -space-y-px">
          <div>
            <input id="username" formControlName="username" type="text" autocomplete="email" required
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              [placeholder]="translation['EMAIL_OR_USER']">
          </div>
          <div>
            <input id="password" formControlName="password" type="password" autocomplete="current-password" required
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              [placeholder]="translation['PASSWORD']">
          </div>
        </div>

        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <input id="remember-me" name="remember-me" type="checkbox" [checked]="rememberMe"
              class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              (change)="onChangeRemember($event)">
            <label for="remember-me" class="ml-2 block text-sm text-gray-900"> {{translation['REMEMBER_ME']}} </label>
          </div>
          <div class="text-sm text-right">
            <a href="/auth/forgot-password"
              class="font-medium text-indigo-600 hover:text-indigo-500">{{translation['PASSWORD_FORGOTTEN']}} </a>
          </div>
        </div>
        <div>
          <button type="submit"
            class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <svg class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                  clip-rule="evenodd" />
              </svg>
            </span>
            {{ translation['SIGN_IN']}}

          </button>
        </div>
      </form>
    </div>
  </div>
</div>