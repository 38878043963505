import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IWeekRoom } from '../../interfaces/weeks.interface';
import { StoreService } from '../store/store.service';

const TOKEN_KEY = 'intim-trier-auth-token';
const USER_KEY = 'intim-trier-user';
const LOCALE_KEY = 'intim-trier-locale';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  constructor(private http: HttpClient, private storeService: StoreService) {
    if (this.isLoggedIn) {
      this.storeService.login.next(true);
    } else {
      this.storeService.login.next(false);
    }
  }

  signOut(): void {
    window.localStorage.clear();
  }

  get isLoggedIn(): boolean {
    const user = window.localStorage.getItem(USER_KEY);
    if (user && user !== null) {
      return true;
    }
    return false;
  }

  public saveToken(token: string): void {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string | null {
    return window.localStorage.getItem(TOKEN_KEY);
  }

  public removeToken() {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.removeItem(USER_KEY);
  }

  public saveUser(user: any): void {
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.setItem(USER_KEY, user);
  }

  public getUser(): any {
    const user = window.localStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }
    return null;
  }

  public saveLocale(locale: string): void {
    window.localStorage.removeItem(LOCALE_KEY);
    window.localStorage.setItem(LOCALE_KEY, locale);
  }

  public getLocale(): string | null {
    return window.localStorage.getItem(LOCALE_KEY);
  }

  public getUserReadOnlyOld(room: IWeekRoom | null = null): boolean {
    const userStr = window.localStorage.getItem(USER_KEY);
    if (userStr) {
      return JSON.parse(userStr).readOnly;
    }
    return false;
  }

  public getUserReadOnly(room: IWeekRoom | null = null): boolean {
    const userStr = window.localStorage.getItem(USER_KEY);
    if (room === null || room.room._id !== 'null') {
      if (userStr) {
        return JSON.parse(userStr).readOnly;
      }
      return false;
    }
    return false;
  }

  public getUserAllowDocuments(): boolean {
    const userStr = window.localStorage.getItem(USER_KEY);
    if (userStr) {
      return JSON.parse(userStr).allowDocuments;
    }
    return false;
  }

  public getUserAllowContracts(): boolean {
    const userStr = window.localStorage.getItem(USER_KEY);
    if (userStr) {
      return JSON.parse(userStr).allowContracts;
    }
    return false;
  }

  public isAdmin(): boolean {
    const userStr = window.localStorage.getItem(USER_KEY);
    if (userStr) {
      return JSON.parse(userStr).roles.includes('admin') || JSON.parse(userStr).roles.includes('superadmin');
    }
    return false;
  }

  public postSubscriptionKeys(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'subscriptions', data, httpOptions).pipe(map((data: any) => data));
  }
}
