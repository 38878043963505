import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { DndModule } from 'ngx-drag-drop';
import { IconsModule } from 'src/app/shared/icons/icons.module';
import { CashComponent } from './cash/cash.component';
import { AppSidebarComponent } from './components/app-sidebar/app-sidebar.component';
import { BookingDetailComponent } from './components/booking-detail/booking-detail.component';
import { HouseItemComponent } from './components/house-item/house-item.component';
import { HousePricesComponent } from './components/house-prices/house-prices.component';
import { HouseComponent } from './components/house/house.component';
import { ImageModalComponent } from './components/image-modal/image-modal.component';
import { OverviewItemComponent } from './components/overview-item/overview-item.component';
import { TableColumnFilterComponent } from './components/table-column-filter/table-column-filter.component';
import { DepositComponent } from './deposit/deposit.component';
import { HomeRoutingModule } from './home-routing';
import { HomeComponent } from './home/home.component';
import { OverviewComponent } from './overview/overview.component';

@NgModule({
  declarations: [
    OverviewComponent,
    HomeComponent,
    HouseComponent,
    CashComponent,
    DepositComponent,
    BookingDetailComponent,
    OverviewItemComponent,
    HouseItemComponent,
    HousePricesComponent,
    AppSidebarComponent,
    ImageModalComponent,
    TableColumnFilterComponent,
  ],
  exports: [
    BookingDetailComponent,
    HouseComponent,
    OverviewItemComponent,
    HouseItemComponent,
    HousePricesComponent,
    ImageModalComponent,
    TableColumnFilterComponent,
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    IconsModule,
    DndModule,
    NzDatePickerModule,
    NzModalModule,
    NzButtonModule,
    NzTagModule,
    NzTableModule,
    NzBadgeModule,
    NzPopconfirmModule,
    NzCardModule,
    NzDescriptionsModule,
    NzTypographyModule,
    NzSelectModule,
    NzFormModule,
    NzInputModule,
    NzPopoverModule,
    NzAvatarModule,
    NzSpinModule,
  ],
})
export class HomeModule {}
