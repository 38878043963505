import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { StoreService } from 'src/app/core/services/store/store.service';
import { TokenStorageService } from 'src/app/core/services/token/token-storage.service';
import { MenuAnimation } from 'src/app/shared/animations/menu.animation';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    MenuAnimation,
    trigger('optionFade', [
      transition(':enter', [
        style({
          opacity: 0,
          transform: 'scale(0.95)',
        }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({
          opacity: 1,
          transform: 'scale(1)',
        }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(0.95)' })),
      ]),
    ]),
  ],
})
export class HomeComponent implements OnInit {
  isCollapsed: boolean = false;

  menuShow4Desktop: boolean = false;
  menuShow4Mobile: boolean = false;
  user: any;
  showLocaleMenu: boolean = false;

  locale?: string | null;

  constructor(
    private router: Router,
    private tokenService: TokenStorageService,
    private _swPush: SwPush,
    private notification: NzNotificationService,
    private storeService: StoreService
  ) {}

  currentPage = 0;

  localesList = [
    { code: 'en-US', label: 'English' },
    { code: 'de', label: 'Deutschland' },
  ];

  ngOnInit(): void {
    this.requestPermission();
    this.user = this.tokenService.getUser();
    const locale = this.tokenService.getLocale();
    if (locale) {
      this.locale = locale;
    } else {
      this.locale = 'english';
    }
    this.storeService.sidebarCollapsed.subscribe((isCollapsed) => {
      this.isCollapsed = isCollapsed;
    });
  }

  overview(): void {
    this.currentPage = 0;
    this.router.navigate(['/']);
  }

  houses(): void {
    this.currentPage = 1;
    this.router.navigate(['/houses']);
  }

  cash(): void {
    this.currentPage = 2;
    this.router.navigate(['/cash']);
  }

  calendar(): void {
    this.currentPage = 3;
    this.router.navigate(['/calendar']);
  }

  contacts(): void {
    this.currentPage = 4;
    this.router.navigate(['/contacts']);
  }

  toggleMenu4Desktop(): void {
    this.menuShow4Desktop = !this.menuShow4Desktop;
  }
  toggleMenu4Mobile(): void {
    this.menuShow4Mobile = !this.menuShow4Mobile;
  }

  clickLocale(): void {
    this.showLocaleMenu = !this.showLocaleMenu;
  }

  onChangeLocale(locale: string) {
    this.locale = locale;
    this.tokenService.saveLocale(locale);
    this.showLocaleMenu = false;
  }

  requestPermission = () => {
    if (!this._swPush.isEnabled) {
      window.Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          this.subscribeToPush();
        } else {
          this.notification.error('Settings', 'Notification is not enabled.');
        }
      });
    } else {
      this.subscribeToPush();
    }
  };

  arrayBufferToString(buffer: ArrayBuffer): string {
    return String.fromCharCode.apply(null, Array.from(new Uint8Array(buffer)));
  }

  private async subscribeToPush() {
    try {
      const sub = await this._swPush.requestSubscription({
        serverPublicKey: environment.vapidPublicKey,
      });
      // console.log(sub.toJSON());
      this.tokenService.postSubscriptionKeys(sub.toJSON()).subscribe({
        next: (data) => {
          // console.log(data);
        },
      });
    } catch (err) {
      console.error('Could not subscribe due to:', err);
    }
  }

  onActivate(event: any) {
    console.log('---- HOME on activate -----');
    console.log(event);
    try {
      event.setActive();
    } catch (error) {}
    console.log(event.constructor.name);
    console.log('---- HOME on activate END -----');
  }

  onDeactivate(event: any) {
    console.log('---- HOME on deactivate -----');
    console.log(event);
    try {
      event.setInactiv();
    } catch (error) {}
    console.log(event.constructor.name);
    console.log('---- HOME on deactivate END -----');
  }
}
